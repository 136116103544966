export const kpiMultiLineChartPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'spline'
    },
    credits: { enabled: false },
    colors: ['#E0E0E0', '#B0B0B0'], // Gray colors for placeholder
    xAxis: {
        allowDecimals: false,
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'] // Sample x-axis categories
    },
    tooltip: {
        enabled: false
        // shared: true,
        // formatter: function () {
        //     // let s = '';
        //     // this.points.forEach((point: any) => {
        //     //     s += `<br/>${point.series.name}: ${point.point.name ?? point.y}`;
        //     // });
        //     // return s;
        // }
    },
    plotOptions: {
        area: {
            marker: {
                enabled: true,
                symbol: 'circle',
                radius: 2,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            }
        }
    },
    yAxis: [{
        title: { text: null }, // Placeholder axis
        labels: {
            formatter: function () {
                return this.value.toString();
            }
        }
    }],
    series: [
        {
            name: '',
            data: [15, 18, 20, 25, 30, 35, 40, 45, 50, 55].map((y, i) => ({ y, name: '' })),
            type: 'spline'  // Specify the type explicitly
        },
        {
            name: '',
            data: [10, 12, 15, 18, 22, 28, 32, 35, 38, 42].map((y) => ({ y, name: '' })),
            type: 'spline'  // Specify the type explicitly
        }
    ]
};


export const kpiPieChartPlaceholder: Highcharts.Options = {
    title: { text: null },
    credits: { enabled: false },
    colors: ['#E0E0E0'],
    chart: {
        type: 'pie',
        height: 341,
        animation: null
    },
    plotOptions: {
        pie: {
            allowPointSelect: false,
            dataLabels: {
                enabled: true,
                connectorShape: 'straight',
                distance: 30,
                style: { fontWeight: 'bold' },
                formatter: function () {
                    return '';
                }
            },
            showInLegend: false,
        },
        series: { animation: true }
    },
    tooltip: {
        enabled: false
        // headerFormat: '',
        // pointFormatter: function () {
        //     // const point = this as any;
        //     // return `<b>${point.name}: ${point.displayValue} (${point.percentage.toFixed(0)}%)</b>`;
        //     return '';
        // }
    },
    legend: { enabled: true, align: 'center' },
    series: [{
        type: 'pie',
        data: [
            { name: '', y: 40, label: '40', value: 40, text: '40', displayValue: '40' } as any,
            { name: '', y: 30, label: '30', value: 30, text: '30', displayValue: '30' } as any,
            { name: '', y: 20, label: '20', value: 20, text: '20', displayValue: '20' } as any,
            { name: '', y: 10, label: '10', value: 10, text: '10', displayValue: '10' } as any,
        ]
    }]
}

export const stackedColumnChartPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'column',
        height: 400
    },
    credits: {
        enabled: false
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            day: '%m/%d/%Y'
        },
        tickInterval: 24 * 3600 * 1000 * 2, // Show a tick every 2 days
        labels: {
            formatter: function () {
                const date = new Date(this.value);
                return date.toLocaleDateString();
            }
        }
    },
    yAxis: {
        title: {
            text: ''
        },
        stackLabels: {
            enabled: false
        }
    },
    tooltip: {
        enabled: false
        // formatter: function () {
        //     // const point = <any>this.point;
        //     // const xValue = new Date(this.x); // Convert x value to a date
        //     // const formattedDate = isNaN(xValue.getTime()) ? 'Invalid Date' : xValue.toLocaleDateString();
        //     // return `<b>${formattedDate}</b><br/>${this.series.name}: ${point.label ?? point.y}`;
        // }
    },
    plotOptions: {
        series: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    legend: {
        enabled: true,
        verticalAlign: 'bottom'
    },
    // Set colors to different shades of grey to indicate placeholder
    colors: ['#C0C0C0', '#808080'], // Light grey and darker grey
    series: [
        {
            name: '',
            data: [
                { x: new Date('2024-01-01').getTime(), y: 10 },
                { x: new Date('2024-01-02').getTime(), y: 20 },
                { x: new Date('2024-01-03').getTime(), y: 30 },
                { x: new Date('2024-01-04').getTime(), y: 40 },
                { x: new Date('2024-01-05').getTime(), y: 50 },
                { x: new Date('2024-01-06').getTime(), y: 60 },
                { x: new Date('2024-01-07').getTime(), y: 70 },
                { x: new Date('2024-01-08').getTime(), y: 80 },
                { x: new Date('2024-01-09').getTime(), y: 90 },
                { x: new Date('2024-01-10').getTime(), y: 100 }
            ],
            type: 'column'
        },
        {
            name: '',
            data: [
                { x: new Date('2024-01-01').getTime(), y: 100 },
                { x: new Date('2024-01-02').getTime(), y: 90 },
                { x: new Date('2024-01-03').getTime(), y: 80 },
                { x: new Date('2024-01-04').getTime(), y: 70 },
                { x: new Date('2024-01-05').getTime(), y: 60 },
                { x: new Date('2024-01-06').getTime(), y: 50 },
                { x: new Date('2024-01-07').getTime(), y: 40 },
                { x: new Date('2024-01-08').getTime(), y: 30 },
                { x: new Date('2024-01-09').getTime(), y: 20 },
                { x: new Date('2024-01-10').getTime(), y: 10 }
            ],
            type: 'column'
        }
    ]
};

export const stackedBarChartPercentPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'bar',
        height: 400
    },
    credits: {
        enabled: false
    },
    xAxis: {
        categories: ['Category A', 'Category B', 'Category C', 'Category D'],
        title: { text: null },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
        labels: {
            style: {
                fontSize: '12px',
                fontFamily: 'Helvetica'
            }
        }
    },
    yAxis: {
        tickInterval: 20,
        labels: {
            format: '{value}%'
        },
        title: {
            text: ''
        },
        reversedStacks: false
    },
    tooltip: {
        enabled: false
        // formatter: function () {
        //     // return this.x + '<br>' + this.series.name + ': <b>' + this.point.y + '</b><br> % of Total: <b>' + (this.point.percentage).toFixed(2) + '%</b>';
        //     return '';
        // }
    },
    plotOptions: {
        series: {
            stacking: 'percent',
            dataLabels: {
                enabled: false
            }
        }
    },
    legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal'
    },
    colors: ['#C0C0C0', '#808080'], // Light grey and darker grey
    series: [
        {
            name: '',
            data: [10, 20, 30, 40],
            type: 'bar'
        },
        {
            name: '',
            data: [40, 30, 20, 10],
            type: 'bar'
        }
    ]
};

export const kpiLineComparisonChartPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'spline'
    },
    credits: { enabled: false },
    colors: ['#C0C0C0'], // Single grey color for placeholder
    xAxis: {
        allowDecimals: false,
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'] // Sample x-axis categories
    },
    tooltip: {
        enabled: false
        // shared: true,
        // formatter: function () {
        //     // let s = '';
        //     // this.points.forEach((point: any) => {
        //     //     s += `<br/>${point.series.name}: ${point.point.name ?? point.y}`;
        //     // });
        //     // return s;
        //     return '';
        // }
    },
    plotOptions: {
        area: {
            marker: {
                enabled: true,
                symbol: 'circle',
                radius: 2,
                states: {
                    hover: {
                        enabled: true
                    }
                }
            }
        }
    },
    yAxis: [{
        title: { text: 'Sample Metric' }, // Placeholder axis
        labels: {
            formatter: function () {
                return this.value.toString();
            }
        }
    }],
    series: [
        {
            name: '',
            data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((y) => ({ y, name: '' })),
            type: 'spline'  // Specify the type explicitly
        }
    ]
};

export const columnChartPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'column',
        height: 400
    },
    credits: {
        enabled: false
    },
    colors: ['#C0C0C0'], // Single grey color for placeholder
    xAxis: {
        categories: ['Category A', 'Category B', 'Category C'], // Sample x-axis categories
        title: { text: null }
    },
    yAxis: {
        title: {
            text: ''
        },
        stackLabels: {
            enabled: false
        }
    },
    tooltip: {
        enabled: false
    },
    plotOptions: {
        series: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    legend: {
        enabled: true,
        verticalAlign: 'bottom'
    },
    series: [
        {
            name: '',
            data: [30, 50, 40],
            type: 'column'
        }
    ]
};

export const kpiBarChartPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'column',
        height: 120
    },
    credits: {
        enabled: false
    },
    colors: ['#C0C0C0'], // Single grey color for placeholder
    xAxis: {
        categories: ['Metric 1', 'Metric 2', 'Metric 3'], // Sample x-axis categories
        title: { text: null },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0
    },
    yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
            enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
    },
    legend: {
        enabled: false
    },
    series: [
        {
            name: '',
            data: [30, 50, 40],
            type: 'column'
        }
    ]
};

export const kpiSimplePieChartPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'pie',
        height: 341
    },
    credits: {
        enabled: false
    },
    colors: ['#C0C0C0'], // Single grey color for placeholder
    plotOptions: {
        pie: {
            allowPointSelect: false,
            dataLabels: {
                enabled: false
            },
            showInLegend: true,
            states: {
                hover: {
                    halo: {
                        size: 0
                    }
                }
            }
        }
    },
    tooltip: {
        enabled: false
    },
    legend: {
        enabled: true,
        align: 'center'
    },
    series: [
        {
            name: '',
            data: [
                { name: '', y: 30 },
                { name: '', y: 50 },
                { name: '', y: 20 }
            ],
            type: 'pie'
        }
    ]
};

export const kpiSparkAreaFillPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'area',
        height: 120
    },
    credits: {
        enabled: false
    },
    colors: ['#C0C0C0'], // Single grey color for placeholder
    xAxis: {
        title: { text: null },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
            enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
    },
    yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
            enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        area: {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    x2: 1,
                    y1: 0,
                    y2: 0
                },
                stops: [
                    [0, 'rgba(192, 192, 192, 0.2)'],
                    [1, 'rgba(192, 192, 192, 0.55)']
                ]
            }
        }
    },
    series: [
        {
            name: '',
            data: [10, 20, 15, 30, 25],
            type: 'area'
        }
    ]
};

export const lineChartWithForecastPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'line',
        height: 400
    },
    credits: {
        enabled: false
    },
    colors: ['#C0C0C0'], // Single grey color for placeholder
    xAxis: {
        categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
        title: {
            text: null
        }
    },
    yAxis: {
        title: {
            text: 'Sales Volume'
        },
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    tooltip: {
        enabled: false
    },
    series: [
        {
            name: '',
            data: [1, 2, 3, 4, 5],
            type: 'line'
        }
    ]
};

export const scatterPlotPlaceholder: Highcharts.Options = {
    title: { text: null },
    chart: {
        type: 'scatter',
        zoomType: 'xy'
    },
    credits: {
        enabled: false
    },
    colors: ['#C0C0C0'], // Single grey color for placeholder
    xAxis: {
        title: {
            text: 'Metric 1'
        },
        labels: {
            enabled: false
        }
    },
    yAxis: {
        title: {
            text: 'Metric 2'
        },
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    tooltip: {
        enabled: false
    },
    series: [
        {
            type: 'scatter',
            data: [
                { x: 1, y: 1 },
                { x: 2, y: 2 },
                { x: 3, y: 3 },
                { x: 4, y: 4 }
            ]
        }
    ]
};