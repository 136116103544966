<mat-card class="mat-elevation-z1 element-card tri-metric-element" style="background-color: white; color: black;">
  
  <!-- Loaded View -->
  <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
    <mat-card-title>
      <i class="fa pad-right" [ngClass]="titleIconClasses"></i><b>{{ title }}</b>
      <div class="pull-right ellipses-dropdown" *ngIf="settings.helpTextKey">
        <button
          title="options"
          mat-button
          class="card-options-button"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"
        >
          <i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            style="outline: none"
            (click)="openHelpTextClicked()"
          >
            {{ "Help" | translate : locale | async }}
          </button>
        </mat-menu>
      </div>
    </mat-card-title>
    <mat-card-content
      class="card-block target-card billboard"
      style="margin-bottom: 0px;"
    >
      <div class="metrics-container">
        <div class="metric-item">
          <div style="padding-right: 10px">
              <i
                  [ngClass]="[
                  getTrendArrowIconClass(metrics[0].trend),
                  getTrendIconColor(metrics[0].trend)
                  ]"
                  style="font-size:40px"
              ></i>
          </div>
          <div fxLayout="column">
              <div style="font-size: 1.25rem">{{ metrics[0].label }}</div>
              <div style="font-size: 2.5rem; font-weight: 500; line-height: 1">
                  {{ metrics[0].value }}
              </div>
          </div>            
          <div *ngIf="metrics[0].subtitleOneValue" class="subtitle">
            <span class="subtitle-label">{{ metrics[0].subtitleOneLabel }}</span>
            <span class="subtitle-value">{{ metrics[0].subtitleOneValue }}</span>
          </div>
        </div>
        <div class="metric-item-separator"></div>
  
        <div class="display:flex; flex-direction: row; align-items: center;">
          <div style="display:flex; flex-direction: row; align-items: center;">
            <div style="padding-right: 10px">
              <i
                [ngClass]="[
                  getTrendArrowIconClass(metrics[1].trend),
                  getTrendIconColor(metrics[1].trend)
                ]"
              ></i>            
            </div>
            <div fxLayout="column">
              <div style="font-size: .75rem">{{ metrics[1].label }}</div>
              <div style="font-size: 1.5rem; font-weight: 500; line-height: 1">
                {{ metrics[1].value }}
              </div>
            </div>
          </div>
          <div class="stacked-item-seperator"></div>
          <div style="display:flex; flex-direction: row; align-items: center;">
              <div style="padding-right: 10px">
                <i
                  [ngClass]="[
                    getTrendArrowIconClass(metrics[2].trend),
                    getTrendIconColor(metrics[2].trend)
                  ]"
                ></i>            
              </div>
              <div fxLayout="column">
                <div style="font-size: .75rem">{{ metrics[2].label }}</div>
                <div style="font-size: 1.5rem; font-weight: 500; line-height: 1">
                  {{ metrics[2].value }}
                </div>
              </div>
            </div>
        </div>
      </div>
    </mat-card-content>
  </ng-container>

  <!-- Loading View with Pulse Animations -->
  <ng-template #loadingView>
    <mat-card-title>
      <div class="title-placeholder" syncPulse style="height: 24px; width: 150px; background-color: #e0e0e0; border-radius: 4px;"></div>
    </mat-card-title>
    
    <mat-card-content class="card-block target-card billboard" style="margin-bottom: 0px;">
      <div class="metrics-container">

        <!-- First Metric Placeholder -->
        <div class="metric-item">
          <div style="padding-right: 10px">
            <div class="icon-placeholder" syncPulse style="width: 40px; height: 40px; background-color: #e0e0e0; border-radius: 50%;"></div>
          </div>
          <div fxLayout="column">
            <div class="label-placeholder" syncPulse style="width: 100px; height: 20px; background-color: #e0e0e0; border-radius: 4px;"></div>
            <div class="value-placeholder" syncPulse style="width: 150px; height: 32px; background-color: #e0e0e0; border-radius: 4px; margin-top: 8px;"></div>
          </div>
        </div>

        <div class="metric-item-separator"></div>

        <!-- Second and Third Metric Placeholders -->
        <div class="display:flex; flex-direction: row; align-items: center;">
          <div style="display:flex; flex-direction: row; align-items: center;">
            <div style="padding-right: 10px">
              <div class="icon-placeholder" syncPulse style="width: 30px; height: 30px; background-color: #e0e0e0; border-radius: 50%;"></div>
            </div>
            <div fxLayout="column">
              <div class="label-placeholder" syncPulse style="width: 70px; height: 16px; background-color: #e0e0e0; border-radius: 4px;"></div>
              <div class="value-placeholder" syncPulse style="width: 100px; height: 24px; background-color: #e0e0e0; border-radius: 4px; margin-top: 8px;"></div>
            </div>
          </div>
          <div class="stacked-item-separator"></div>
          <div style="display:flex; flex-direction: row; align-items: center;">
            <div style="padding-right: 10px">
              <div class="icon-placeholder" syncPulse style="width: 30px; height: 30px; background-color: #e0e0e0; border-radius: 50%;"></div>
            </div>
            <div fxLayout="column">
              <div class="label-placeholder" syncPulse style="width: 70px; height: 16px; background-color: #e0e0e0; border-radius: 4px;"></div>
              <div class="value-placeholder" syncPulse style="width: 100px; height: 24px; background-color: #e0e0e0; border-radius: 4px; margin-top: 8px;"></div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </ng-template>

</mat-card>
