
import { Enums, IEnums } from '../../enums/enums';
import { } from '../cache/localCache.service';
import { LocaleService } from '../../locale/locale.service';
import { Injectable } from '@angular/core';
import * as Models from '../../models/models-index';
import { Subject, Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { tap, map, switchMap, filter } from 'rxjs/operators';
import { AppState } from '../../../_store/app-state.model';
import { Store, select } from '@ngrx/store';
import { AppSelectors } from '../../../_store/selector-types';
import { FilterBarService } from '../../filter/filter-bar.service';
import { ConfigurationService } from '../config/config.service';
import { FilterActions } from '../../filter/store/action-types';
import { FilterSelectors, ReportViewFilterSelection } from '../../filter/store';
import { TypeCheck } from '../../hy-filter-panel-module/Utils';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';

export interface IFilterDates {
  startDate: Date;
  endDate: Date;
  previousStartDate: Date;
  previousEndDate: Date;
}

export interface IDaterangeRestrictions {
  restrict: boolean;
  numberOfMonthsToShow: number;
}

@Injectable({ providedIn: 'root'})
export class FilterService {

  private enums: IEnums = Enums;
  filterName: string;
  activePageTitle$ = new BehaviorSubject('');

  orgLookups: Models.IOrgLookup[];
  currentSalesMonth: Date;
  dayOfSalesMonth: number;
  locale: string;

  private secondaryNavResized = new Subject<string>();
  private daterangeRestrictions = new Subject<IDaterangeRestrictions>();

  secondaryNavResized$ = this.secondaryNavResized.asObservable();
  daterangeRestrictions$ = this.daterangeRestrictions.asObservable();

  currentFilterModel: Models.IFilterModel;
  defaultFilter: Models.IFilterModel;

  // Observables for requesting and returning filter breadcrumbs
  private filterBreadcrumbsRequested = new Subject<void>();
  private filterBreadcrumbsReturned = new Subject<string[]>();
  filterBreadcrumbsRequested$ = this.filterBreadcrumbsRequested.asObservable();
  filterBreadcrumbsReturned$ = this.filterBreadcrumbsReturned.asObservable();

  filter$: Observable<Models.IFilterModel>;
  currentReportName$: Observable<string>;

  private isRouteStable = new BehaviorSubject<boolean>(true);

  // Auth/User info
  private authInfo: Models.IAuthenticationInfo;
  constructor(
    private store: Store<AppState>,
    private localeService: LocaleService,
    private authService: AuthenticationService,
    private filterBarService: FilterBarService,
    private configService: ConfigurationService,
    private router: Router) {
      // Fix for legacy components firing multiple
      // filter subscription logic/fetch requests to api
      // whenever the route changes because components are
      // not destroyed on time.
      this.router.events.pipe().subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          // Navigation is starting, block filtering until properly redirected
          this.isRouteStable.next(false);
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
           // After navigation ends or the route is fully activated, reenable filtering
          this.isRouteStable.next(true);
        }
      });

      this.filter$ = this.isRouteStable.pipe(
        switchMap((stable) => {
          if (stable) {
            return this.store.select(AppSelectors.selectCurrentRouteData).pipe(
              map(routeData => routeData.reportName),
              switchMap(reportName => this.filterBarService.getReportFilterModel(reportName))
            );
          } else {
            // If the route is unstable, return an empty observable to stop emissions
            return EMPTY;
          }
        })
      );
      
      // we can get this anywhere we need from this.filters$ or refrence this directly
      //  in this component - this is referenced in a lot of unused legacy / unconverted report functions
      this.filterBarService.getDefaultFilterModel().pipe(
        tap(defaultFilter => this.defaultFilter = defaultFilter)
      ).subscribe();

      this.localeService.locale$.pipe(tap(loc => {
        this.locale = loc;
      })).subscribe(),

      this.currentReportName$ = this.store.select(AppSelectors.selectCurrentRouteData).pipe(
        map(data => data.reportName || 'UNKNOWN')
      );
  }

  initialize() {
    this.authInfo = this.authService.getUser();
  }

  getCurrentFilterModel() {
    return this.currentFilterModel;
  }

  getDefaultFilterModel() {
    return this.defaultFilter;
  }

  setActivePageTitle(title: string) {
    this.activePageTitle$.next(title);
  }

  getActivePageTitle(): string {
    return '';
  }

  restrictDaterangeOptions(options: IDaterangeRestrictions) {
    this.daterangeRestrictions.next(options);
  }

  triggerNavResizeEvent(filterState: string) {
    this.secondaryNavResized.next(filterState);
  }

  // Requesting/returning filter breadcrumbs
  requestBreadcrumbString() {
    this.filterBreadcrumbsRequested.next();
  }

  returnBreadcrumbString(breadcrumbs: string[]) {
    this.filterBreadcrumbsReturned.next(breadcrumbs);
  }

  convertStringToStringArray(value: string): string[] {
    if (!value) {
      return null;
    } else {
      const array = value.split(',');
      return array;
    }
  }

  convertStringToNumberArray(value: string): number[] {
    if (!value) {
      return null;
    } else {
      const array = value.split(',').map(Number);
      return array;
    }
  }

  // initializeReportViewFilters(reportName: string, reportViewFilters: Models.ReportViewFilter[]) {
  //   this.store.dispatch(FilterActions.initializeReportViewFilters({ reportName, filters: reportViewFilters }));
  // }

  updateReportViewFilter(reportName: string, selection: ReportViewFilterSelection) {
    this.store.dispatch(FilterActions.updateReportViewFilterSelection({ reportName, selection }));
  }

  updateReportViewFilters(reportName: string, selections: ReportViewFilterSelection[]) {
    this.store.dispatch(FilterActions.updateReportViewFilterSelections({ reportName, selections }));
  }

  getReportViewFilters(reportName: string) {
    return this.store.select(FilterSelectors.selectReportViewFiltersForReport({ reportName }));
  }

  getReportViewFilterSelection(reportName: string, filterName: string) {
    return this.store.select(FilterSelectors.selectReportViewFilterSelection({ reportName, filterName }));
  }

  getReportViewFilterRequestModel(reportName: string): Observable<Models.ReportRequestModel> {
    return this.store.select(FilterSelectors.selectReportViewFilterSelections({ reportName })).pipe(
      filter(selections => ((selections || []).length > 0)),
      // tap(selections => console.log('[filter.service getReportViewFilterRequestModel] selections: ', selections)),
      map(selections => {
        const requestModel = <Models.ReportRequestModel>{ reportType: reportName, filters: { culture: this.locale == 'en' ? 'en-US' : this.locale } };
        const requestFilterSelections: Models.ReportFilterRequestSelection[] = [];
        selections?.forEach(selection => {
          const name = selection.item.name;

          requestFilterSelections.push(this.convertToReportFilterValue(selection));

        });

        return {...requestModel, filterSelections: requestFilterSelections};
      })
    );
  }

  convertToReportFilterValue(filterSelection: ReportViewFilterSelection): Models.ReportFilterRequestSelection {
    if (TypeCheck.isDateRangeValue(filterSelection.item.value)) {
      return {
        name: filterSelection.name,
        value: {
          name: filterSelection.item.value.name,
          startDate: filterSelection.item.value.startDate,
          endDate: filterSelection.item.value.endDate
        }
      };
    } else if (TypeCheck.isHierarchyValueArray(filterSelection.item.value)) {
      return {
        name: filterSelection.item.value[0].name,
        value: <Models.ReportFilterHierarchyValue>{
          name: filterSelection.item.value[0].name,
          hierarchyTypeId: filterSelection.item.value[0].hierarchyTypeId,
          value: filterSelection.item.value.map(h => h.key).join(",")
        }
      };
    } else if (TypeCheck.isStringArray(filterSelection.item.value)) {
      return {
        name: filterSelection.name,
        value: filterSelection.item.value.join(",")
      };
    } else {
      return {
        name: filterSelection.name,
        value: filterSelection.item.value
      };
    }
  }
}
