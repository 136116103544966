<mat-card class="element-card">
  <mat-card-content>
    <div style="display:flex;" fxLayout="column" fxLayoutAlign="space-around center" style="height: 120px;">
      <div style="width: 100%; margin-top: 28px; margin-bottom: -20px; z-index: 2; font-size: 1rem;"
        fxLayoutAlign="center center">
        {{primaryMetricName}} &nbsp; <span
          style="font-size: 1.8rem; font-weight: 500;">{{primaryMetricDisplayValue}}</span>
      </div>
      <div style="width: 100%">
        <div *ngIf="isDataSetLoaded$ | async; else loading">
          <div [chart]="currentChart"></div>
        </div>
        <ng-template #loading>
          <div [chart]="placeholderChart" syncPulse></div>
        </ng-template>
      </div>
    </div>
  </mat-card-content>
</mat-card>