import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import * as Models from '../../../models/models-index';
import { BehaviorSubject, map, startWith } from 'rxjs';

@Component({
  selector: 'stacked-two-kpi-metric',
  templateUrl: './stacked-two-kpi-metric.component.html',
  styleUrls: ['../panel-elements.scss', './stacked-two-kpi-metric.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StackedTwoKpiMetricComponent implements Models.ElementComponent, OnInit {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: Models.ElementSettings;

  primaryMetricValue;
  primaryMetricTrend;
  primaryMetricLabel;
  secondaryMetricValue;
  secondaryMetricTrend;
  secondaryMetricLabel;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {

  }

  getTrendArrowIconClass(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null || trend === 0) {
      return 'fa fa-horizontal-rule';
    } else if (trend > 0) {
      return 'fa fa-chevron-circle-up chevron-icon';
    } else if (trend < 0) {
      return 'fa fa-chevron-circle-down chevron-icon';
    }
  }

  getTrendIconColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null) {
      return 'bg-yellow';
    } else if (!isTrendUp) {
      return 'bg-red';
    } else if (!!isTrendUp) {
      return 'bg-green';
    }
  }

  getTrendColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);
    if (isTrendUp == null) {
      return 'text-yellow';
    } else if (!!isTrendUp) {
      return 'text-green';
    } else if (!isTrendUp) {
      return 'text-red';
    }
  }

  isTrendUp(value: number) {
    if (!value || value === 0)
      return null;

    return value > 0
      ? this.settings.metricInverted ? false : true
      : this.settings.metricInverted ? true : false;
  }

  throwError(errorMessage: string): never {
    throw new Error(errorMessage);
  }

  private processData(dataSet: Models.DataSet): void {
    const primaryColumn = dataSet.columns.find(c => c.name === this.settings.metricName)
      ?? this.throwError(`Invalid Config - '${this.settings.metricName}' is not available in dataset '${dataSet.name}'`);
    const primaryMetricIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    const primaryMetricTrendIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricTrend);

    const secondaryColumn = dataSet.columns.find(c => c.name === this.settings.secondaryMetricName)
      ?? this.throwError(`Invalid Config - '${this.settings.secondaryMetricName}' is not available in dataset '${dataSet.name}'`);
    const secondaryMetricIndex = dataSet.columns.findIndex(col => col.name === this.settings.secondaryMetricName);
    const secondaryMetricTrendIndex = dataSet.columns.findIndex(col => col.name === this.settings.secondaryMetricTrend);

    const primaryRowValue = dataSet.rows[0][primaryMetricIndex]
    const primaryTrendRowValue = dataSet.rows[0][primaryMetricTrendIndex];

    this.primaryMetricValue = primaryRowValue.label ?? primaryRowValue.value;
    this.primaryMetricTrend = primaryTrendRowValue.value;
    this.primaryMetricLabel = primaryColumn.displayName ?? primaryColumn.name;

    const secondaryRowValue = dataSet.rows[0][secondaryMetricIndex];
    const secondaryTrendRowValue = dataSet.rows[0][secondaryMetricTrendIndex];

    this.secondaryMetricValue = secondaryRowValue.label ?? secondaryRowValue.value;
    this.secondaryMetricTrend = secondaryTrendRowValue.value;
    this.secondaryMetricLabel = secondaryColumn.displayName ?? secondaryColumn.name;
  }
}
