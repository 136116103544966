<mat-card class="element-card">
  <mat-card-content>
    <div style="display: flex;" fxLayout="column" fxLayoutAlign="space-around center" style="height: 150px; overflow: hidden;">

      <!-- Loaded View -->
      <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
        <div style="display:flex;" fxLayout="column" fxLayoutAlign="space-around center"
        style="height: 150px; overflow: hidden;">
        <div style="width: 100%; margin-top: 81px;">
          <div style="z-index: 0" [chart]="currentChart"></div>
        </div>
        <div fxLayoutAlign="center center">
          <div fxLayout="column"
            style="width: 128px; margin-top: -132px; margin-bottom: 155px; z-index: 2; font-size: 0.65rem;"
            fxLayoutAlign="center flex-start">
            <div fxLayout="row" style="width: 128px;"
              fxLayoutAlign="flex-start center" *ngFor="let item of legendData">
              <span fxLayoutAlign="flex-start center" style="white-space: nowrap;">
                <div class="legend-box" [ngStyle]="{'background-color': item.color}">
                </div>
                <div class="legend-text">
                  {{item.label}}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      </ng-container>

      <!-- Loading View with Pulse Animations -->
      <ng-template #loadingView>
        <div style="width: 100%; margin-top: 81px;">
          <div class="chart-placeholder" syncPulse style="height: 150px; background-color: #e0e0e0; border-radius: 4px;"></div>
        </div>
        <div fxLayoutAlign="center center">
          <div fxLayout="column" style="width: 128px; margin-top: -132px; margin-bottom: 155px; z-index: 2; font-size: 0.65rem;" fxLayoutAlign="center flex-start">
            <div fxLayout="row" style="width: 128px;" fxLayoutAlign="flex-start center">
              <span fxLayoutAlign="flex-start center" style="white-space: nowrap;">
                <div class="legend-box-placeholder" syncPulse style="width: 16px; height: 16px; background-color: #cccccc; border-radius: 2px;"></div>
                <div class="legend-text-placeholder" syncPulse style="width: 60px; height: 16px; background-color: #e0e0e0; border-radius: 4px; margin-left: 10px;"></div>
              </span>
            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </mat-card-content>
</mat-card>
