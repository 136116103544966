import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import * as Models from '../../../models/models-index';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import { BehaviorSubject, map, startWith } from 'rxjs';

@Component({
  selector: 'kpi-semi-circle-donut',
  templateUrl: './kpi-semi-circle-donut.component.html',
  styleUrls: ['../panel-elements.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiSemiCircleDonutComponent implements Models.ElementComponent, OnInit {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: any;

  currentChart: any;
  legendData: any[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  private generateChart(seriesData: any[]) {

    const chartSeries: SeriesOptionsType[] = [{
      type: 'pie',
      data: seriesData,
      innerSize: '68%',
      states: {
        hover: {
          enabled: true,
          halo: null
        }
      }
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      chart: {
        height: 275,
        animation: null
      },
      tooltip: {
        outside: true,
        pointFormat: '<b>{point.displayValue}</b>',
        style: {
          zIndex: 100
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          dataLabels: {
            enabled: false,
            distance: -40,
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },
      series: chartSeries
    });

    return chart;
  }

  hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  private processData(dataSet: Models.DataSet): void {
    let seriesData = [];
    this.legendData = [];

    this.settings.chartMetrics.forEach(chartMetric => {
      let metricIndex = dataSet.columns.findIndex(col => col.name === chartMetric.metricName);
      let metricDisplayName = dataSet.columns[metricIndex].displayName;
      let metricItem = dataSet.rows[0][metricIndex];
      seriesData.push({
        name: metricDisplayName,
        displayValue: metricItem.label,
        y: metricItem.value,
        color: this.hexToRGB(chartMetric.color, 1)
      });
      this.legendData.push({
        color: chartMetric.color,
        label: metricDisplayName
      })
    })

    this.currentChart = this.generateChart(seriesData);
  }
}
