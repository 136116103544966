import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ElementSettings } from '../../../models/report-view.models';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import * as Models from '../../../models/models-index';
import { ChartService } from '../../chart/chart.service';
import { map, startWith, takeWhile } from 'rxjs/operators';
import { getChartColors } from '../panel-utils';
import { kpiPieChartPlaceholder } from '../../../constants/highcharts-placeholders';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kpi-pie-chart',
  templateUrl: './kpi-pie-chart.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-pie-chart.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiPieChartComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() settings: ElementSettings;
  @Input() selectedMetric: string;

  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));

  componentActive: boolean = true;
  currentChart: Chart;
  locale: any;

  placeholderChart: Chart;
  
  constructor(private chartService: ChartService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.initializePlaceholder();

    this.chartService.reflowChart$.pipe(
      takeWhile(() => this.componentActive),
      map(() => {
        setTimeout(() => {
          if (this.currentChart && this.currentChart.ref) {
            this.currentChart.ref.reflow();
          }
          this.changeDetector.markForCheck();
        }, 200);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private generateChart(seriesData: any[]) {
    const colors = getChartColors(this.settings);
    return new Chart({
      credits: {
        enabled: false
      },
      title: { text: ' ' },
      colors: colors,
      chart: {
        type: 'pie',
        height: 341,
        animation: null
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          dataLabels: {
            enabled: true,
            connectorShape: 'straight',
            distance: 30,
            style: {
              fontWeight: 'bold'
            },
            formatter: function () {
              const point = this.point as any;
              return `${point.name}: ${point.displayValue} (${point.percentage.toFixed(0)}%)`;
            }
          },
          showInLegend: false,
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          },
          events: {
            click: function (e) { }
          }
        },
        series: {
          animation: true,
          point: {
            events: {}
          }
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          const point = this as any;
          return `<b>${point.name}: ${point.displayValue} (${point.percentage.toFixed(0)}%)</b>`;
        }
      },
      legend: {
        enabled: true,
        align: 'center'
      },
      series: [{
        type: 'pie',
        data: seriesData
      }]
    });
  }

  private processData(dataSet: Models.DataSet): void {
    // Ensure selectedMetric is valid
    let metricIndex = dataSet.columns.findIndex(col => col.name === this.selectedMetric);

    if (metricIndex === -1) {
      // Attempt to set a default metric
      const defaultMetricColumn = dataSet.columns.find(col => col.type === 'metric');
      if (defaultMetricColumn) {
        this.selectedMetric = defaultMetricColumn.name;
        metricIndex = dataSet.columns.findIndex(col => col.name === this.selectedMetric);
      } else {
        // No valid metric found
        this.dataSetSubject.next(undefined);
        return;
      }
    }

    const dimIndex = dataSet.columns.findIndex(col => col.name === this.settings.dimensionName);

    // Check if dimension index is valid
    if (dimIndex === -1) {
      this.dataSetSubject.next(undefined);
      return;
    }

    let seriesData = [];

    // Copy rows and order by metric descending
    let rows = [...dataSet.rows];
    rows.sort((a, b) => (b[metricIndex].value as number) - (a[metricIndex].value as number));

    rows.forEach(row => {
      if (
        !row[dimIndex]?.value ||
        row[dimIndex].label === '' ||
        this.settings.dimensionRowFilters?.some(f => f === row[dimIndex].value)
      ) {
        return;
      }

      if (!row[metricIndex]?.value) {
        return;
      }

      seriesData.push({
        name: row[dimIndex].label ?? row[dimIndex].value,
        displayValue: row[metricIndex].label ?? row[metricIndex].value,
        y: row[metricIndex].value,
      });
    });

    this.currentChart = this.generateChart(seriesData);
  }

  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }

    this.placeholderChart = new Chart(kpiPieChartPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);
  }
}
