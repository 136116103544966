  <!-- Original Loaded View (unchanged) -->
  <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
    <mat-card class="mat-elevation-z1 kpi-metric-detailed-element" style="background-color: white; color: black">
      <mat-card-title>
        <i class="fa pad-right" [ngClass]="titleIconClasses"></i><b>{{title}}</b>
        <div class="pull-right ellipses-dropdown" *ngIf="!!settings.helpTextKey">
          <button title="options" mat-button class="card-options-button" #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">{{ "Help" | translate : locale
              | async}}</button>
          </mat-menu>
        </div>
      </mat-card-title>
      <mat-card-content class="card-block target-card billboard" style="margin-bottom:0px">
        <table>
          <tbody>
            <tr>
              <td>
                <b class="metric-value">
                  {{ metricValue }}
                </b>
                <i [ngClass]="[
                  getTrendArrowIconClass(metricValuePOPValue),
                  getTrendIconColor(metricValuePOPValue)
                ]" style="font-size: 1.1em"></i>
              </td>
            </tr>
            <tr *ngIf="subtitleOne">
              <td class="box-label" style="font-size: 1.2rem">
                <span class="subtitle-label">{{'Prev. Period' | translate | async}}:</span>
                <span class="subtitle-value">{{ subtitleOne }}</span>
              </td>
            </tr>
            <tr *ngIf="subtitleTwo">
              <td class="box-label" style="font-style: italic; font-size: 1.2rem">
                <span class="subtitle-label">{{ subtitleTwoLabel }}</span>
                <span class="subtitle-value">{{ subtitleTwo }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
      <mat-card-footer>
        <table style="width:100%">
          <tbody>
            <tr>
              <td *ngIf="!!settings.metricNamePOP" style="width:50%">
                <div *ngIf="metricValuePOP">
                  {{'POP' | translate | async}}:
                  <b [ngClass]="getTrendColor(metricValuePOPValue)">
                    {{ metricValuePOP }}</b>
                  <i style="margin-left: 5px; font-size: 1.1em" [ngClass]="[
                    getTrendArrowIconClass(metricValuePOPValue),
                    getTrendIconColor(metricValuePOPValue)
                  ]"></i>
                </div>
                <div *ngIf="!metricValuePOP">{{'POP' | translate | async}}: <b>N/A</b></div>
              </td>
              <td *ngIf="!!settings.metricNameYOY" style="width:50%; text-align:right">
                <div *ngIf="metricValueYOY">
                  {{'YOY' | translate | async}}:
                  <b [ngClass]="getTrendColor(metricValueYOYValue)">
                    {{ metricValueYOY }}</b>
                  <i style="margin-left: 5px; font-size: 1.1em" [ngClass]="[
                    getTrendArrowIconClass(metricValueYOYValue),
                    getTrendIconColor(metricValueYOYValue)
                  ]"></i>
                </div>
                <div *ngIf="!metricValueYOY">{{'YOY' | translate | async}}: <b>N/A</b></div>
                <!-- <div *ngIf="!metricValueYOY">
                        YOY: <b>N/A</b>
                    </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-footer>
    </mat-card>
  </ng-container>

<!-- Placeholder View (for when data is not loaded) -->
<ng-template #loadingView>
  <mat-card class="mat-elevation-z1 kpi-metric-detailed-element" style="background-color: white; color: black">
    <mat-card-title style="padding-bottom: 4px;">
      <div class="title-placeholder" syncPulse
        style="height: 18px; width: 80%; background-color: #f0f0f0; border-radius: 4px; margin-bottom: 4px;">
      </div>
    </mat-card-title>

    <mat-card-content class="card-block target-card billboard" style="padding-bottom: 0px; margin-bottom: 0px;">
      <table>
        <tbody>
          <tr>
            <td>
              <b class="metric-value">
                <span class="value-placeholder" syncPulse
                  style="display: inline-block; width: 60%; height: 32px; background-color: #f0f0f0; border-radius: 4px; margin-bottom: 0px;"></span>
              </b>
              <span class="icon-placeholder" syncPulse
                style="display: inline-block; width: 20px; height: 20px; background-color: #f0f0f0; border-radius: 50%; margin-left: 5px;"></span>
            </td>
          </tr>
          <tr>
            <td class="box-label" style="font-size: 1.1rem; margin-top: 4px; margin-bottom: 0px;">
              <span class="subtitle-placeholder" syncPulse
                style="display: inline-block; width: 50%; height: 16px; background-color: #f0f0f0; border-radius: 4px;"></span>
            </td>
          </tr>
          <!-- <tr>
            <td class="box-label" style="font-size: 1.1rem; margin-top: 4px; margin-bottom: 0px;">
              <span class="subtitle-placeholder" syncPulse
                style="display: inline-block; width: 60%; height: 16px; background-color: #f0f0f0; border-radius: 4px;"></span>
            </td>
          </tr> -->
        </tbody>
      </table>
    </mat-card-content>

    <mat-card-footer style="padding-top: 2px; padding-bottom: 2px;">
      <table style="width: 100%">
        <tbody>
          <tr>
            <td style="width: 50%">
              <span class="footer-placeholder" syncPulse
                style="display: inline-block; width: 60%; height: 16px; background-color: #f0f0f0; border-radius: 4px; margin-bottom: 0px;"></span>
            </td>
            <td style="width: 50%; text-align: right">
              <span class="footer-placeholder" syncPulse
                style="display: inline-block; width: 60%; height: 16px; background-color: #f0f0f0; border-radius: 4px; margin-bottom: 0px;"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-footer>
  </mat-card>
</ng-template>
