import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ElementSettings } from '../../../models/report-view.models';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import * as Models from '../../../models/models-index';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';
import { SeriesOptionsType } from 'highcharts';
import { kpiSimplePieChartPlaceholder } from '../../../constants/highcharts-placeholders';

@Component({
  selector: 'kpi-simple-pie-chart',
  templateUrl: './kpi-simple-pie-chart.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-simple-pie-chart.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiSimplePieChartComponent implements Models.ElementComponent, OnInit {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData();
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: ElementSettings;

  currentChart: any;
  title: string;
  iconClass: string;
  helpTextKey: string;
  helpTextTitle: string;
  locale: any;
  colors: string[] = ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'];

  placeholderChart: Chart;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.initializePlaceholder();
  }

  private generateChart(seriesData: any[]) {
    return new Chart({
      credits: {
        enabled: false
      },
      title: { text: ' ' },
      colors: this.colors,
      chart: {
        type: 'pie',
        height: 341,
        //height: this.settings.height || 341, // height of dealer performance table: 353px, padding: 12px
        // width: 280, // inherit width -- set as an input if we need to change this, but probably want to inherit this
        animation: null
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          },
          events: {
            click: function (e) { }
          }
        },
        series: {
          animation: true,
          point: {
            events: {}
          }
        }
      },
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return '<b>' + this.name + '</b>: ' + this.y + '<br><b>' + (this.percentage).toFixed(0) + '%</b>';
        }
      },
      legend: {
        enabled: true,
        align: 'center'
      },
      series: [<Highcharts.SeriesPieOptions>{
        data: seriesData
      }]
    });
  }

  private processData(): void {
    this.title = this.settings.title;
    this.iconClass = this.settings.titleIconClasses;
    this.helpTextKey = this.settings.helpTextKey;
    this.helpTextTitle = this.settings.helpTextTitle;

    let seriesData = [];

    let index = 0;

    /*
      * This needs to be rewritten to do dataset based discovery
      */

    // this.settings.chartMetrics.forEach(chartMetric => {
    //   let metricIndex = this.dataSet.columns.findIndex(col => col.name === chartMetric.metricName);
    //   let metricDisplayName = this.dataSet.columns[metricIndex].displayName;
    //   let metricItem = this.dataSet.rows[0][metricIndex];

    //   this.colors[index] = chartMetric.color || this.colors[index];

    //   seriesData.push({
    //     name: metricDisplayName,
    //     displayValue: metricItem.label,
    //     y: metricItem.value
    //   });

    //   index += 1;
    // })

    this.currentChart = this.generateChart(seriesData);
  }

  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }
    
    this.placeholderChart = new Chart(kpiSimplePieChartPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);

    this.changeDetectorRef.markForCheck();
  }
}
