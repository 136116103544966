<mat-card class="element-card kpi-metric-element mat-elevation-z1">
  
  <!-- Loaded View -->
  <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
    <mat-card-title class="element-title">
      {{metricLabel}}
    </mat-card-title>
    <mat-card-content>
      <div class="element-card-content">
        <div class="kpi-metric-element-text">
          <b class="metric-value">{{metricValue}}</b>
          <i [ngClass]="[getTrendArrowIconClass(metricTrend), getTrendIconColor(metricTrend)]"></i>
        </div>
        <div *ngIf="subtitleOne" class="subtitle">
          <span class="subtitle-label">{{ subtitleOneLabel }}</span>
          <span class="subtitle-value">{{ subtitleOne }}</span>
        </div>
      </div>
    </mat-card-content>
  </ng-container>

  <!-- Loading View with Pulse Animations -->
  <ng-template #loadingView>
    <mat-card-title class="element-title">
      <div class="title-placeholder" syncPulse style="height: 24px; width: 150px; background-color: #e0e0e0; border-radius: 4px;"></div>
    </mat-card-title>

    <mat-card-content>
      <div class="element-card-content">
        <div class="kpi-metric-element-text">
          <div class="value-placeholder" syncPulse style="height: 40px; width: 80%; background-color: #e0e0e0; border-radius: 4px;"></div>
          <div class="icon-placeholder" syncPulse style="display: inline-block; width: 20px; height: 20px; background-color: #e0e0e0; border-radius: 50%; margin-left: 10px;"></div>
        </div>
        
        <div class="subtitle">
          <div class="subtitle-placeholder" syncPulse style="height: 20px; width: 60%; background-color: #e0e0e0; border-radius: 4px;"></div>
        </div>
      </div>
    </mat-card-content>
  </ng-template>

</mat-card>
