<mat-card class="element-card">

  <!-- Loaded View -->
  <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
    <mat-card-title class="element-title">
      {{elementLabel}}
    </mat-card-title>
    <mat-card-content>
      <div style="display:flex;" fxLayout="column" fxLayoutAlign="space-around center"
        style="height: 120px; overflow: hidden;">
        <div style="width: 100%;">
          <div [chart]="currentChart"></div>
        </div>
      </div>
    </mat-card-content>
  </ng-container>

  <!-- Loading View with Pulse Animations -->
  <ng-template #loadingView>
    <mat-card-title class="element-title">
      <div class="title-placeholder" syncPulse
        style="height: 24px; width: 150px; background-color: #e0e0e0; border-radius: 4px;"></div>
    </mat-card-title>

    <mat-card-content>
      <div style="display: flex;" fxLayout="column" fxLayoutAlign="space-around center"
        style="height: 120px; overflow: hidden;">
        <div style="width: 100%;">
          <div [chart]="placeholderChart" syncPulse></div>
        </div>
      </div>
    </mat-card-content>
  </ng-template>
</mat-card>