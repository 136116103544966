import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { ElementComponent } from '../../../models/models-index';
import { PanelElementFormattingService } from '../panel-element-formatting.service';
import { PanelElementResolverService } from '../panel-element-resolver.service';

@Component({
  selector: 'ghost-panel',
  templateUrl: './ghost-panel.component.html',
  styleUrls: ['./ghost-panel.component.scss']
})
export class GhostPanelComponent implements OnInit, Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panelConfig: Models.Panel;
  private componentInstances: Models.ElementComponent[] = [];

  private _dataSets: Models.DataSet[] = [];
  @Input()
  set dataSets(value: Models.DataSet[]) {
    this._dataSets = value;
    if(this._dataSets?.length > 0)
    {
      this.assignDataToElement();
      return;
    }

    this.componentInstances.forEach(instance => {
      instance.dataSet = null;
    });
  }
  get dataSets(): Models.DataSet[] {
    return this._dataSets;
  }  @Input() rowPanelId: string;

  panelTitle;

  constructor(private resolverService: PanelElementResolverService, 
    private panelFormattingService: PanelElementFormattingService, 
    private renderer2: Renderer2) { }

  ngOnInit(): void {
    this.generateElements(this.panelConfig);
  }

  generateElements(panelConfig) {
    const config = panelConfig;// || this.getDefaultPanelConfig();
    // const datasets = dataSets;// || this.getDefaultDataSets();
    this.panelTitle = config?.title;

    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    this.componentInstances = [];

    config.elements.forEach(element => {
      const componentFactory = this.resolverService.resolveElementComponent(element.type)
      const componentRef = viewContainerRef.createComponent<ElementComponent>(componentFactory);
      componentRef.instance.settings = element.settings;
      this.componentInstances.push(componentRef.instance);
      this.renderer2.addClass(componentRef.location.nativeElement, 'ghost-panel-element');
      this.renderer2.setAttribute(componentRef.location.nativeElement, 'style', this.panelFormattingService.getPanelStyles(element.settings))
    })
  }

  private assignDataToElement(): void {
    this.componentInstances.forEach(instance => {
      const dataSet = this.dataSets.find(ds => ds.name === instance.settings.dataSet);
      instance.dataSet = dataSet;
    });
  }
}
