import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import * as Models from '../../../models/models-index';
import {  DataSet, ElementComponent } from '../../../models/report-view.models';

@Component({
  selector: 'data-panel',
  templateUrl: './data-panel.component.html',
  styleUrls: ['../panel-elements.scss', './data-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DataPanelComponent implements ElementComponent, OnInit {
  @Input() dataSet: DataSet;
  @Input() settings: any;


  constructor() {
  }

  ngOnInit() {
  }

  getDataPanel(dataSet: DataSet){

  }
}
