
<div class="filter-content-container" class="flex flex-col">

    <div class="flex justify-evenly border-b border-gray-200">

        <div class="h-12 w-full md:w-1/2 flex items-center border-r border-gray-200">

            <div class="filter-content-wrapper p-0 w-full">

                <div class="w-full h-12 flex items-center relative">

                    <label for="search" class="sr-only">Search</label>
                    <input #searchInput  placeholder="Search" (focus)="searchHasFocus = true" (focusout)="searchHasFocus = false" [(ngModel)]="searchTerm"
                           class="context-search font-light block w-full border-transparent pl-12 placeholder-gray-400 placeholder-italic focus:border-transparent sm:text-sm focus:ring-0 ring-0">

                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4 text-gray-400">
                        <span [innerHtml]="svgIcons.searchSvg | safeSvg"></span>
                    </div>

                    <div class="w-6 h-6 cursor-pointer" *ngIf="searchHasFocus || searchTerm" (click)="clearSearchTerm($event)">

                        <span [innerHtml]="closeIcon | safeSvg"></span>

                    </div>
                </div>

                <mat-progress-bar *ngIf="showProgress" mode="determinate" value="400" style="height: 1px;">
                </mat-progress-bar>
            </div>

        </div>

        <div class="hidden md:flex items-center w-1/2" style="height: 50px;">

            <div class="filter-content-wrapper w-full px-2">

                <span *ngIf="!selected.length">None selected</span>

                <div *ngIf="selected.length" class="selected-detail-wrapper"
                    style="display: flex; justify-content: space-between; align-items: center;">

                    <div>{{selected.length}} selected</div>

                    <button mat-button (click)="clearAllSelected()">
                        <span class="text-primary-600 font-inter">CLEAR SELECTED</span>
                    </button>

                </div>

            </div>

        </div>

    </div>

    <div class="" style="display: flex; flex-direction: row; justify-content: space-evenly; height: 277px;">

        <div class="w-full md:w-1/2 flex flex-col border-r border-gray-300">

            <div class="w-full h-12 flex items-center border-b border-gray-300 pl-4">

                <ng-container *ngIf="!isLoading; else selectedLoader;">
                    <mat-checkbox [indeterminate]="isIndeterminate()" [checked]="isChecked()"
                        (change)="headerCheckboxChanged($event)" labelPosition="right">{{checkBoxHeaderLabel}}
                    </mat-checkbox>
                </ng-container>

            </div>

            <div class="overflow-y-auto overscroll-contain scrollbar-thin scrollbar-thumb scrollbar-track hover:scrollbar-thumb pr-3" style="height: 247px;">

                <div class="filter-content-wrapper" style="display: flex; flex-direction: column;">
                    
                    <mat-selection-list class="selection-list" #panelDataSelectList>
                    
                        <ng-container *ngIf="!isLoading; else listLoader;">

                            <mat-list-option *ngFor="let value of filteredPanelData" class="mat-primary" checkboxPosition="before" (click)="filterDataOptionChanged(value)" [value]="value">
                                <div class="truncate" [matTooltip]="value.display">{{value.display}}</div>
                            </mat-list-option>
    
                        </ng-container>

                    </mat-selection-list>

                </div>

            </div>

        </div>

        <div class="hidden md:flex w-1/2 overflow-y-auto overscroll-contain scrollbar-thin scrollbar-thumb scrollbar-track hover:scrollbar-thumb pr-3" style="height: 278px;">

            <div class="filter-content-wrapper w-full">

                <div class="selected-list-wrapper w-full">

                    <div *ngFor="let value of selected; let idx = index;" class="selected-list-item container hover:bg-gray-300">

                        <!-- <div class="selected-item-ripple-container" matRipple [matRippleTrigger]="idx"></div> -->

                        <div matRipple [matRippleColor]="rippleColor" class="flex h-12 items-center justify-between cursor-pointer font-inter text-base px-3"
                            (click)="handleRemoveSelectedFilter(value)">

                            <div class="truncate">{{value.display}}</div>

                            <div class="w-6 h-6 cursor-pointer flex items-center justify-center">

                                <span [innerHtml]="closeIcon | safeSvg"></span>
        
                            </div>

                        </div>


                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<ng-template #selectedLoader>

    <div class="w-full h-12 flex items-center border-b border-gray-300 pl-4">
        <div syncPulse>
          <div class="rounded h-4 w-52 bg-highlight-tint"></div>
        </div>
      </div>

</ng-template>

<ng-template #listLoader>

    <div class="w-full h-12 flex items-center pl-4">
        <div syncPulse>
          <div class="rounded h-4 w-52 bg-highlight-tint"></div>
        </div>
      </div>

      <div class="w-full h-12 flex items-center pl-4">
        <div syncPulse>
          <div class="rounded h-4 w-52 bg-highlight-tint"></div>
        </div>
      </div>

      <div class="w-full h-12 flex items-center pl-4">
        <div syncPulse>
          <div class="rounded h-4 w-52 bg-highlight-tint"></div>
        </div>
      </div>

</ng-template>