import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import * as Models from '../../../models/models-index';
import { BehaviorSubject, map, startWith } from 'rxjs';

@Component({
  selector: 'kpi-metric',
  templateUrl: './kpi-metric.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-metric.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiMetricComponent implements Models.ElementComponent, OnInit {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: any;

  metricValue;
  metricTrend;
  metricLabel;
  subtitleOne;
  subtitleOneLabel: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  getTrendArrowIconClass(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null || trend === 0) {
      return 'fa fa-horizontal-rule';
    } else if (trend > 0) {
      return 'fa fa-chevron-circle-up chevron-icon';
    } else if (trend < 0) {
      return 'fa fa-chevron-circle-down chevron-icon';
    }
  }

  getTrendIconColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null) {
      return 'bg-yellow';
    } else if (!isTrendUp) {
      return 'bg-red';
    } else if (!!isTrendUp) {
      return 'bg-green';
    }
  }

  getTrendColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);
    if (isTrendUp == null) {
      return 'text-yellow';
    } else if (!!isTrendUp) {
      return 'text-green';
    } else if (!isTrendUp) {
      return 'text-red';
    }
  }

  isTrendUp(value: number) {
    if (!value || value === 0)
      return null;

    return value > 0
      ? this.settings.metricInverted ? false : true
      : this.settings.metricInverted ? true : false;
  }

  private processData(dataSet: Models.DataSet): void {
    const metricIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    const metricTrendIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricTrend);
    const metricPreviousPeriodIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousPeriod);

    this.metricValue = dataSet.rows[0][metricIndex].label ?? dataSet.rows[0][metricIndex].value;
    this.metricTrend = dataSet.rows[0][metricTrendIndex].value;
    this.metricLabel = this.settings.displayName ?? dataSet.columns[metricIndex].displayName ?? dataSet.columns[metricIndex].name;

    const metricPreviousPeriod = dataSet.rows[0][metricPreviousPeriodIndex];
    this.subtitleOne = metricPreviousPeriod?.label ?? metricPreviousPeriod?.value;
    this.subtitleOneLabel = this.settings.subtitleOneLabel ?? "Prev. Period:";
  }
}
