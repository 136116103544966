import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import { ChartService } from '../../chart/chart.service';
import { getChartColors } from '../panel-utils';
import { BehaviorSubject, map, startWith, takeWhile } from 'rxjs';
import { columnChartPlaceholder } from '../../../constants/highcharts-placeholders';

@Component({
  selector: 'column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['../panel-elements.scss', './column-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnChartComponent implements ElementModels.ElementComponent, OnInit, OnDestroy {
  @Input() set dataSet(value: ElementModels.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<ElementModels.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetric: string;

  dimensionName: () => string = () => this.settings.dimensionNames[0] || '';
  chartColors: () => string[] = () => getChartColors(this.settings);
  componentActive = true;
  currentChart: Chart;
  placeholderChart: Chart;

  constructor(
    private chartService: ChartService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initializePlaceholder();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private generateChart(selectedMetricLabel: string, seriesData: any[], categories: any[]) {
    const chartSeries: SeriesOptionsType[] = [{
      type: 'column',
      data: seriesData,
      name: selectedMetricLabel
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: getChartColors(this.settings),
      chart: {
        type: 'column',
        height: 400
      },
      xAxis: {
        categories: categories, // categories here represent regions
        // Removed datetime-specific properties
      },
      yAxis: {
        title: {
          text: ''
        },
        stackLabels: {
          enabled: false
        }
      },
      tooltip: {
        formatter: function () {
          const point = <any>this.point;
          return `<b>${this.x}</b><br/>${this.series.name}: ${point.displayValue}`;
        },
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      legend: {
        enabled: true,
        verticalAlign: 'bottom'
      },
      series: chartSeries
    });

    return chart;
  }

  private processData(dataSet: ElementModels.DataSet): void {
    if (!this.settings.dimensionNames || this.settings.dimensionNames.length === 0) {
      throw new Error('At least one dimension must be available.');
    }

    const dimIndex = dataSet.columns.findIndex(col => col.name === this.settings.dimensionNames[0]);
    const dimColumn = dataSet.columns[dimIndex];
    const metricIndex = dataSet.columns.findIndex(col => col.name === this.selectedMetric);
    if (dimIndex === -1 || metricIndex === -1) {
      throw new Error('Invalid dimension or metric selection.');
    }

    const selectedMetricLabel = dataSet.columns[metricIndex].displayName || dataSet.columns[metricIndex].name;

    const categories = [];
    const seriesDataMap = new Map();

    dataSet.rows.forEach(row => {
      if (!row[dimIndex].value || row[dimIndex].label === ''
        || this.settings.dimensionRowFilters?.some(f => f === row[dimIndex].value || f === row[dimIndex].label)) {
        return;
      }

      const category = row[dimIndex].value;
      const metricValue = row[metricIndex].value;

      if (!categories.includes(category)) {
        categories.push(category);
      }

      seriesDataMap.set(category, {
        name: category,
        y: metricValue,
        displayValue: row[metricIndex].label || metricValue
      });
    });

    const seriesData = Array.from(seriesDataMap.values());

    this.currentChart = this.generateChart(selectedMetricLabel, seriesData, categories);

    this.chartService.reflowChart$.pipe(
      takeWhile(() => this.componentActive),
      map(() => {
        setTimeout(() => {
          this.currentChart.ref.reflow();
        }, 400);
      })
    ).subscribe()
  }

  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }
    
    this.placeholderChart = new Chart(columnChartPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);

    this.changeDetectorRef.markForCheck();
  }
}
