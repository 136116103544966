import { Component, Input, OnInit } from '@angular/core';
import * as Models from '../../../models/models-index';
import { HelpTextService } from '../../../services/help/helpText.service';
import { BehaviorSubject, map, startWith } from 'rxjs';

@Component({
  selector: 'kpi-metric-detailed',
  templateUrl: './kpi-metric-detailed.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-metric-detailed.scss'],
})
export class KpiMetricDetailedComponent implements Models.ElementComponent, OnInit {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: Models.ElementSettings;

  metricValue: string;
  metricValuePreviousPeriod: string;
  metricValuePreviousYear: string;
  metricValuePOP: string;
  metricValueYOY: string;
  metricLabel: string;
  title: string;
  titleIconClasses: string;
  subtitleOne: string;
  subtitleTwo: string;
  subtitleTwoLabel: string;
  metricValuePOPValue: number;
  metricValueYOYValue: number;

  constructor(private helpTextService: HelpTextService) {}

  ngOnInit() {
  }

  getTrendArrowIconClass(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null || trend === 0) {
      return 'fa fa-horizontal-rule';
    } else if (trend > 0) {
      return 'fa fa-chevron-circle-up chevron-icon';
    } else {
      return 'fa fa-chevron-circle-down chevron-icon';
    }
  }

  getTrendIconColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    if (isTrendUp === null) {
      return 'bg-yellow';
    } else if (!isTrendUp) {
      return 'bg-red';
    } else {
      return 'bg-green';
    }
  }

  getTrendColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);
    if (isTrendUp == null) {
      return 'text-yellow';
    } else if (isTrendUp) {
      return 'text-green';
    } else {
      return 'text-red';
    }
  }

  isTrendUp(value: number) {
    if (value === null || value === undefined || value === 0 || this.settings == null) return null;

    return value > 0 ? !this.settings.metricInverted : this.settings.metricInverted;
  }

  openHelpTextClicked(): void {
    if (this.settings) {
      this.helpTextService.openHelp(this.settings.helpTextKey, this.settings.helpTextTitle);
    }
  }

  private processData(dataSet: Models.DataSet): void {
    const row = dataSet.rows[0];
    const metricIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    const metricPreviousPeriodIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousPeriod);
    const metricPreviousYearIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousYear);
    const metricIndexPOP = dataSet.columns.findIndex(col => col.name === this.settings.metricNamePOP);
    const metricIndexYOY = dataSet.columns.findIndex(col => col.name === this.settings.metricNameYOY);

    this.metricValue = row[metricIndex]?.label ?? row[metricIndex]?.value?.toString() ?? 'N/A';
    this.metricValuePreviousPeriod = row[metricPreviousPeriodIndex]?.label ?? 'N/A';
    this.metricValuePreviousYear = row[metricPreviousYearIndex]?.label ?? 'N/A';
    this.metricValuePOPValue = row[metricIndexPOP]?.value as number;
    this.metricValueYOYValue = row[metricIndexYOY]?.value as number;
    this.metricValuePOP = row[metricIndexPOP]?.label ?? 'N/A';
    this.metricValueYOY = row[metricIndexYOY]?.label ?? 'N/A';
    this.title =
      this.settings.title ??
      dataSet.columns[metricIndex]?.displayName ??
      dataSet.columns[metricIndex]?.name ??
      '';
    this.titleIconClasses = this.settings.titleIconClasses;
    this.subtitleOne = this.metricValuePreviousPeriod;
  }
}
