import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { BehaviorSubject, Subscription, map, startWith, takeWhile } from 'rxjs';
import * as ElementModels from '../../../models/report-view.models';
import * as Models from '../../../models/models-index';
import * as Highcharts from 'highcharts';
import { Enums } from '../../../enums/enums';
import * as SharedServices from '../../../services/services-index';
import { ChartService } from '../../chart/chart.service';
import { lineChartWithForecastPlaceholder } from '../../../constants/highcharts-placeholders';

@Component({
  selector: 'line-chart-with-forecast',
  templateUrl: './line-chart-with-forecast.component.html',
  styleUrls: ['./line-chart-with-forecast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartWithForecastComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetrics: string[];

  subscriptions: Subscription[] = [];
  currentChart: Chart;
  chartTitle: string = '';
  locale: string;
  componentActive: boolean = true;
  enums = Enums;
  labelTranslator = (val) => this.sharedTranslationService.getLabelTranslation(val, this.locale);

  placeholderChart: Chart;

  constructor(
    private sharedTranslationService: SharedServices.SharedTranslationService,
    private localeService: SharedServices.LocaleService,
    private chartService: ChartService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializePlaceholder();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private calculateDateRange(actualSaleData, forecastedSaleData) {
    const allData = [...actualSaleData, ...forecastedSaleData];
    const dates = allData.map(data => data[0]);
    const minDate = Math.min(...dates);
    const maxDate = Math.max(...dates);
    return { minDate, maxDate };
  }

  private generateChart(actualSaleData, forecastedSaleData, rangeData, mostRecentSaleDay: number, isCurrentMonth: boolean): any {
    const currentDateTime = new Date().setHours(0, 0, 0, 0);

    Highcharts.setOptions({
      colors: [this.enums.chartColors.color1, this.enums.chartColors.color2, '#000000'],
      lang: {
        decimalPoint: this.locale != 'en' ? ',' : '.',
        thousandsSep: this.locale != 'en' ? ' ' : ','
      }
    });

    const seriesData = isCurrentMonth ? [
      {
        name: this.sharedTranslationService.getLabelTranslation('Actual Sales'),
        data: actualSaleData,
        showInLegend: false,
        zIndex: 2,
        type: 'line',
        color: 'black',
        dashStyle: 'Solid'
      },
      {
        name: this.sharedTranslationService.getLabelTranslation('Projected Sales'),
        data: forecastedSaleData,
        showInLegend: false,
        zIndex: 1,
        linkedTo: ':previous',
        type: 'line',
        color: 'black',
        dashStyle: 'ShortDot'
      },
      {
        name: this.sharedTranslationService.getLabelTranslation('Range'),
        data: rangeData,
        showInLegend: false,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: 'black',
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false
        }
      }
    ] : [
      {
        name: this.sharedTranslationService.getLabelTranslation('Actual Sales'),
        data: actualSaleData,
        showInLegend: false,
        zIndex: 2,
        type: 'line',
        color: 'black',
        dashStyle: 'Solid'
      }
    ];

    return {
      xAxis: {
        type: 'number'
      },
      yAxis: {
        title: {
          text: this.sharedTranslationService.getLabelTranslation('Sales Volume')
        },
        labels: {
          formatter: function () {
            const value = this.value;
            if (value >= 1000000) {
              return (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + 'k';
            }
            return value.toString();
          }
        }
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        shadow: false,
        shared: true,
        borderWidth: 1,
        formatter: this.getTooltipFormatter(mostRecentSaleDay, isCurrentMonth),
        borderRadius: 0,
        valueSuffix: ''
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: seriesData
    }
  }

  private getTooltipFormatter(mostRecentSaleDay: number, isCurrentMonth: boolean): Highcharts.TooltipFormatterCallbackFunction {
    let dayLabel = this.labelTranslator('Day');

    const getSaleTypeLabel = (pointX: number) => pointX <= mostRecentSaleDay ? 'Actual Sales' : 'Projected Sales';
    const getTooltipTitle = (x: number, saleTypeLabel: string) => `<b>${dayLabel} ${x} - ${saleTypeLabel}</b>`;
    const getLabel = (point: any, label: string) => `<br/><span style="color:${point.series.options.color}">\u25CF </span>${label}: ${point.y.toLocaleString()}`;
    const getRangeLabels = (point: any) => {
      let lowLabel = `<br/><span style="color:${point.series.options.color}">\u25CF </span>Low: ${point.point.low.toLocaleString()}`;
      let highLabel = `<br/><span style="color:${point.series.options.color}">\u25CF </span>High: ${point.point.high.toLocaleString()}`;
      return { lowLabel, highLabel };
    }

    return function () {
      let result = '';
      if (isCurrentMonth) {
        let saleTypeLabel = getSaleTypeLabel(this.points[0].point.x);
        let toolTipTitle = getTooltipTitle(this.x, saleTypeLabel);
        let actualSalesLabel = null;
        let lowLabel = null;
        let midLabel = null;
        let highLabel = null;

        this.points.forEach(point => {
          if (this.points[0].point.x > mostRecentSaleDay) {
            if (point.series.type === 'arearange') {
              ({ lowLabel, highLabel } = getRangeLabels(point));
            } else {
              midLabel = getLabel(point, 'Mid');
            }
          } else {
            actualSalesLabel = getLabel(point, 'Sales');
          }
        });

        result = toolTipTitle
          + (actualSalesLabel ?? '')
          + (highLabel ?? '')
          + (midLabel ?? '')
          + (lowLabel ?? '');
      } else {
        let toolTipTitle = getTooltipTitle(this.x, 'Actual Sales');
        let salesLabel = null;

        this.points.forEach(point => {
          salesLabel = getLabel(point, 'Sales');
        })

        result = toolTipTitle + salesLabel;
      }

      return result;
    }
  }

  private processData(dataSet: Models.DataSet): void {
    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => this.locale = loc)
    );

    const metricIndexMapping = dataSet.columns.reduce((map, col, index) => {
      map[col.name] = { ...col, index };
      return map;
    }, {} as Record<string, typeof dataSet.columns[0] & { index: number }>);

    let rangeData = [];
    let actualSaleData = [];
    let forecastedSaleData = [];
    let transitionPoint = null;

    const currentDate = new Date();
    // let earliestDate = currentDate.setDate(currentDate.getDate() - 1);

    let mostRecentSaleDate = new Date();
    mostRecentSaleDate.setHours(0, 0, 0, 0);
    mostRecentSaleDate.setDate(mostRecentSaleDate.getDate() - 1);
    let mostRecentSaleDateTime = mostRecentSaleDate.setHours(0, 0, 0, 0);
    let mostRecentSaleDay = null;

    let isCurrentMonth = true;

    dataSet.rows.forEach(row => {
      const date = new Date(row[metricIndexMapping[this.settings.dimensionName].index].label).setHours(0, 0, 0, 0);
      const dateObj = new Date(date);
      if (dateObj.getMonth() !== currentDate.getMonth() || dateObj.getFullYear() !== currentDate.getFullYear()) {
        isCurrentMonth = false;
      }

      const saleDay = row[metricIndexMapping['SaleDay'].index].value;
      const projectedSales = row[metricIndexMapping['ProjectedSales'].index].value;
      const totalSales = row[metricIndexMapping['TotalSales'].index].value;
      const forecastedSalesMin = row[metricIndexMapping['ForecastedSalesMin']?.index]?.value;
      const forecastedSalesMax = row[metricIndexMapping['ForecastedSalesMax']?.index]?.value;

      if (isCurrentMonth) {
        if (date < mostRecentSaleDateTime) {
          actualSaleData.push([saleDay, totalSales || projectedSales]);
        }
        else if (date === mostRecentSaleDateTime) {
          transitionPoint = [saleDay, projectedSales];
          actualSaleData.push([saleDay, totalSales]);
          forecastedSaleData.push(transitionPoint);
          mostRecentSaleDay = saleDay;

          rangeData.push([
            saleDay,
            totalSales ?? null,
            totalSales ?? null
          ]);
        }
        else {
          forecastedSaleData.push([saleDay, projectedSales]);
          rangeData.push([
            saleDay,
            forecastedSalesMin ?? null,
            forecastedSalesMax ?? null
          ]);
        }
      }
      else {
        actualSaleData.push([saleDay, totalSales || projectedSales]);
      }
    });

    const dateRange = this.calculateDateRange(actualSaleData, forecastedSaleData);

    this.currentChart = new Chart(this.generateChart(actualSaleData, forecastedSaleData, rangeData, mostRecentSaleDay, isCurrentMonth));

    this.chartService.reflowChart$.pipe(
      takeWhile(() => this.componentActive),
      map(() => {
        setTimeout(() => {
          this.currentChart.ref.reflow();
        }, 200);
      })
    ).subscribe()
  }

  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }
    
    this.placeholderChart = new Chart(lineChartWithForecastPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);

    this.changeDetectorRef.markForCheck();
  }
}
