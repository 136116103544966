import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import { kpiBarChartPlaceholder } from '../../../constants/highcharts-placeholders';
import { BehaviorSubject, map, startWith } from 'rxjs';

@Component({
  selector: 'kpi-bar-chart',
  templateUrl: './kpi-bar-chart.component.html',
  styleUrls: ['../panel-elements.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiBarChartComponent implements ElementModels.ElementComponent, OnInit {
  @Input() set dataSet(value: ElementModels.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<ElementModels.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: ElementModels.ElementSettings;

  currentChart: any;
  primaryMetricName: string;
  primaryMetricDisplayValue: string;

  placeholderChart: Chart;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.initializePlaceholder();
  }

  private generateChart(seriesData: any[]) {

    const categories = seriesData.map(sd => sd.name);
    const chartSeries: SeriesOptionsType[] = [{
      type: 'column',
      data: seriesData
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      chart: {
        type: 'column',
        showAxes: false,
        height: 120
      },
      tooltip: {
        outside: true,
        pointFormat: '<b>{point.displayValue}</b>',
        style: {
          zIndex: 100
        }
      },
      // AXES.....
      xAxis: {
        categories: categories,
        title: { text: null },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
      },
      yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
      },
      legend: {
        enabled: false
      },
      series: chartSeries
    });

    return chart;
  }

  hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  private processData(dataSet: ElementModels.DataSet): void {
    let seriesData = [];

    // Primary metric configuration
    const primaryMetricIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    this.primaryMetricName = dataSet.columns[primaryMetricIndex].displayName;
    this.primaryMetricDisplayValue = dataSet.rows[0][primaryMetricIndex].label;

    // Chart configuration
    this.settings.chartMetrics.forEach(chartMetric => {
      let metricIndex = dataSet.columns.findIndex(col => col.name === chartMetric);
      let metricColorIndex = this.settings.chartMetrics.findIndex(m => m === chartMetric)
      let metricDisplayName = dataSet.columns[metricIndex].displayName;
      let metricItem = dataSet.rows[0][metricIndex];
      let metricColor = this.settings.chartColors[metricColorIndex];

      seriesData.push({
        name: metricDisplayName,
        displayValue: metricItem.label,
        y: metricItem.value,
        color: this.hexToRGB(metricColor, 1),
        dataLabels: { enabled: false }
      });
    })

    this.currentChart = this.generateChart(seriesData);
  }

  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }
    
    this.placeholderChart = new Chart(kpiBarChartPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);

    this.changeDetectorRef.markForCheck();
  }
}
