import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Observable, of, Subscription, Subject } from 'rxjs';
import * as FilterModels from '../filter.model';
import { Enums } from '../../enums/enums';
import * as FilterPanelTypes from '../../sd-filter-panel-module/types/types';
import { SdFilterPanel } from '../../sd-filter-panel-module/types/sd-filter-panel';
import { SdFilterPanelInputType, FilterPanelCloseType } from '../../sd-filter-panel-module/types/constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { AppSelectors } from '../../../_store/selector-types';
import { FilterBarService } from '../filter-bar.service';
import { ActivatedRoute } from '@angular/router';
import { tap, switchMap, map, filter, takeUntil, startWith } from 'rxjs/operators';
import { ValueConverter } from '../filter-value-converter';
import { FilterSelectors, FilterActions, ReportViewFilterSelection, buildReportViewFilterSelection } from '../store';
import { LocaleService } from '../../locale/locale.service';
import { SharedTranslationService } from '../../locale/translation/shared-translation.service';
import { ConfigurationService } from '../../services/config/config.service';
import { FilterService } from '../../services/services-index';
import { HyFilterPanel } from '../../hy-filter-panel-module/types/filter-panel';
import { FilterPanelParams, IPanelInputData } from '../../hy-filter-panel-module/types/types';
import { IFacetedFilterData, IFacetedFilterDataItem, IFacetedFilterSelection, ISelectedDateRangeInfo, ISelectedFacetInfo, ISelectedHierarchyInfo, ISelectionInfo } from '../../hy-filter-panel-module/types';
import { ReportFilterItem, ReportViewFilter } from '../../models/report-view.models';
import { DisplayValue, FacetedFilterResultBuilder } from '../../hy-filter-panel-module/Utils';

@Component({
  selector: 'app-report-filter-bar',
  templateUrl: './report-filter-bar.component.html',
  styleUrls: ['./report-filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportFilterBarComponent implements OnInit, OnDestroy {
  reportName: string;
  locale: string;
  valueConverter: ValueConverter;
  destroy$ = new Subject<boolean>();
  
  reportViewFilterSelections: ReportViewFilterSelection[] = [];
  reportViewFilterSelections$: Observable<ReportViewFilterSelection[]>;
  reportViewFilters: ReportViewFilter[] = [];
  reportViewFilters$: Observable<ReportViewFilter[]>;
  
  isLoadingFilters$: Observable<boolean>;

  @ViewChild('addFilterButton') addFilterButton: ElementRef;
  
  constructor(
    private store$: Store<AppState>,
    private configService: ConfigurationService,
    private route: ActivatedRoute,
    private filterBarService: FilterBarService,
    private filterService: FilterService,
    private filterPanelRef: HyFilterPanel,
    private localeService: LocaleService,
    private translationService: SharedTranslationService
  ) {
    this.valueConverter = new ValueConverter();
  }

  ngOnInit() {
    const route$ = this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      tap(routeData => {
        this.reportName = routeData.reportName;
      })
    );

    this.reportViewFilters$ = route$.pipe(
      switchMap(routeData => this.store$.select(FilterSelectors.selectReportViewFiltersForReport({ reportName: routeData.reportName }))
        .pipe(
          tap(filters => this.reportViewFilters = filters)
        )));

    this.reportViewFilterSelections$ = route$.pipe(
      switchMap(routeData => this.store$.select(FilterSelectors.selectReportViewFilterSelections({ reportName: routeData.reportName }))
        .pipe(
          tap(selections => {
            this.reportViewFilterSelections = selections;
          }),
          //tap(selections => console.log('Selections', selections))
        )));

    this.localeService.locale$.pipe(
      tap(locale => this.locale = locale)
    ).subscribe();

    this.isLoadingFilters$ = this.reportViewFilterSelections$.pipe(
      map(selections => !selections || selections.length === 0),
      startWith(true) // Start with true to show placeholders initially
    );
  }

  translateLabel = (val) => this.translationService.getLabelTranslation(val, this.locale);

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onFilterClicked($event: MouseEvent, chip: ElementRef, selection: ReportViewFilterSelection) {
    this.show(chip, selection.name, selection.item);
  }

  show(origin: ElementRef, name: string, selected: IFacetedFilterSelection) {
    this.showFilterPanel(origin, selected);
  }

  showFilterPanel(originElement: ElementRef, selected?: IFacetedFilterSelection): void {
    //transform to input data
    const inputData = this.reportViewFilters$.pipe(map(filters => this.transformToInputData(filters)));

    //console.log('>>>>showFilterPanel.selected', selected);

    const panelParams: FilterPanelParams = {
      originElement,
      inputDataType: 'faceted_filter',
      config: {
        type: 'faceted_filter',
        panelTitle: 'Filter',
        panelClass: 'lrgPanel',
        category: '',
        target: 'panel',
        inputData: inputData,
        inputFacetData: selected
      }
    };

    this.filterPanelRef.open(panelParams).afterClosed$.pipe(
      filter(({ dialogData }) => dialogData.resultType === FilterPanelCloseType.applied),
      map(({ dialogData }) => dialogData.data[0] as IFacetedFilterSelection)
    ).subscribe(dialogSelection => {
      //console.log('ReportViewFilters', this.reportViewFilters);
      //console.log('$ [Next Gen Panel] afterClosed$', { selected, dialogSelection });

      if (!dialogSelection)
        return;


        // This is really clunky right now, we'll use the first part of the path to find the filter
        const pathRoot = dialogSelection.path.split('|')[0];

        const filter = this.reportViewFilters.find(f => f.name === pathRoot || f.label === pathRoot);
        const existingSelection = this.reportViewFilterSelections.find(s => s.name === filter.name);

        let selectionToAdd: ReportViewFilterSelection;

        if (existingSelection) {
          selectionToAdd = {...existingSelection, item: dialogSelection};
        } else {
          selectionToAdd = buildReportViewFilterSelection(filter.name, filter.label, dialogSelection, filter.locked, filter.lockable, filter.removeable, filter.visible);
        }

        //console.log('Selection to Add', selectionToAdd);

        const index = this.reportViewFilterSelections.findIndex(s => s.name === filter.name);

        let updateSelections;
        if (index >= 0) {
            // Copy the array and replace the item at the found index
            updateSelections = [...this.reportViewFilterSelections];
            updateSelections[index] = selectionToAdd;
        } else {
            // Item not found, add to the end of the array
            updateSelections = [...this.reportViewFilterSelections, selectionToAdd];
        }

        this.store$.dispatch(FilterActions.updateReportViewFilterSelections({ reportName: this.reportName, selections: updateSelections }));
    })
  }

  private transformToInputData(filters: ReportViewFilter[]): IPanelInputData {
    //const items = filters.map(filter => filter.items?.map(item => this.transformItemToDataItem(item)));
    const items: IFacetedFilterDataItem[] = [];



    filters.forEach(filter => {
      items.push({
        label: filter.label ?? filter.name,
        name: filter.name,
        type: filter.type === "multi-select-hierarchy" ? "hierarchy-facet-container" : "container",
        path: filter.label ?? filter.name,
        searchable: false,
        items: filter.items?.map(item => this.transformItemToDataItem(item))
      });
    });

    const inputData = <IFacetedFilterDataItem>{
      label: 'Filter',
      items: items
    };
    //console.log('Filter Items', items);
    return { inputData: items };
  }

  private transformItemToDataItem(item: ReportFilterItem): IFacetedFilterDataItem {
    return {
      label: item.label ?? item.name,
      name: item.name,
      type: item.type,
      filterType: item.filterType,
      path: item.path,
      value: item.value,
      valueType: item.valueType,
      searchable: item.searchable,
      items: item.items?.map(item => this.transformItemToDataItem(item))
    }
  }

  updateFilter(filterType: string, panelValues: FilterPanelTypes.FilterDataTypeValue[]) {
    switch (filterType) {
      case 'org':
        // translate for OrgFileValues
        const orgSelected = (<FilterPanelTypes.IFilterTreeNode[]>panelValues).map(this.valueConverter.convertToOrgFilterValue);
        this.filterBarService.updateFilterSelected(this.reportName, filterType, orgSelected);
        break;
      case 'vehicle':
        // translate for OrgFileValues
        const vehicleSelected = (<FilterPanelTypes.IFilterTreeNode[]>panelValues).map(this.valueConverter.convertToOrgFilterValue);
        this.filterBarService.updateFilterSelected(this.reportName, filterType, vehicleSelected);
        break;
      default:
        // translate for SimpleValue (pretty much every thing except org)
        const selected = (<FilterPanelTypes.SimpleValue[]>panelValues).map(this.valueConverter.convertToSimpleFilterValue);
        this.filterBarService.updateFilterSelected(this.reportName, filterType, selected);
    }
  }

  lockFilter(selection: ReportViewFilterSelection) {
    const newSelections = this.reportViewFilterSelections.map(s => {
      if (s.name === selection.name) {
        return { ...s, locked: !s.locked };
      }

      return s;
    });

    this.store$.dispatch(FilterActions.updateReportViewFilterSelections({ reportName: this.reportName, selections: newSelections }));
  }

  removeFilter(selection: ReportViewFilterSelection) {
    // remove from selections
    if (!selection.removeable)
      return;

    const updatedSelections = this.reportViewFilterSelections.filter(s => s.name !== selection.name);
    this.store$.dispatch(FilterActions.updateReportViewFilterSelections({ reportName: this.reportName, selections: updatedSelections }));
  }

  resetFilters() {
    this.store$.dispatch(FilterActions.resetReportViewFilters({ reportName: this.reportName }));
  }

  getDisplayValue(selection: ReportViewFilterSelection): string {
    return selection.item.displayValue ?? selection.item.name;
  }
}
