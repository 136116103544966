import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, map, startWith } from 'rxjs';
import * as Models from '../../../models/report-view.models';
import { Chart } from 'angular-highcharts';
import { HelpTextService } from '../../../services/help/helpText.service';
import { LocaleService, TranslationService } from '../../../services/services-index';

@Component({
  selector: 'sd-billboard-benchmark',
  templateUrl: './billboard-benchmark.component.html',
  styleUrls: ['./billboard-benchmark.component.scss']
})
export class BillboardBenchmarkComponent implements Models.ElementComponent, OnInit {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));

  @Input() settings: Models.ElementSettings;
  @Input() panelConfiguration?: Models.PanelConfiguration;
  locale: string;

  metricValue;
  metricValuePreviousPeriod;
  metricValuePreviousYear;
  metricValuePOP;
  metricValueYOY;
  metricLabel;
  title;
  titleIconClasses;
  subtitleOne;
  subtitleOneLabel: string;
  subtitleTwo;
  subtitleTwoLabel: string;
  chart: Chart;
  metricValuePOPValue: number;
  metricValueYOYValue: number;

  constructor(
    private helpTextService: HelpTextService,
    private translateService: TranslationService,
    private localeService: LocaleService
  ) {
    this.localeService.locale$.subscribe(loc => this.locale = loc);
  }

  ngOnInit(): void {
  }

  getBillboardChart(dataSet: Models.DataSet, metricName: string, chartType: string = 'bar'): Chart {
    // NationalAvgSpend
    // data: { x: string, y: number, color: string }[]
    const data: { x: string, y: number, label: string, color?: string }[] = [];

    const billboardMetric = dataSet.rows[0];
    const billboardIndex = dataSet.columns.findIndex(col => col.name === 'metricName');

    const getRowValue = (rowMetricName: string) => {
      const cell = billboardMetric[dataSet.columns.findIndex(col => col.name === rowMetricName)];

      if (!cell)
        console.log('cell not found', rowMetricName);

      return cell.value as number;
    };

    const getRowLabel = (rowMetricName: string) => {
      const cell = billboardMetric[dataSet.columns.findIndex(col => col.name === rowMetricName)];

      if (!cell)
        console.log('cell not found', rowMetricName);

      return (cell.label ?? cell.value) as string;
    };

    var metricValue = getRowValue(metricName);

    const benchmarkEntity = this.settings.dimensionName ?? "Dealer";
    // these next few lines handle the disfunction of a change in our methodology and that we can't pass a prefix/suffix to the metric name as a setting
    
    const metricMode = this.settings.options?.['mode'] ?? 'Brand';
   
    const avgMetricName = metricMode === 'Brand' ? `${metricName}Brand` : `NationalAvg${metricName}`;
    const top10MetricName = metricMode === 'Brand' ? `${metricName}Top10` : `NationalAvg${metricName}Top10`;
    const bottom10MetricName = metricMode === 'Brand' ? `${metricName}Bottom10` : `NationalAvg${metricName}Bottom10`;

    const brandAvgLabel = this.translateService.getLabelTranslation(`${benchmarkEntity} Avg.`, this.locale);
    const top10Label = this.translateService.getLabelTranslation('Top 10%', this.locale);
    const bottom10Label = this.translateService.getLabelTranslation('Bottom 10%', this.locale);

    // brand
    data.push({ x: brandAvgLabel, y: getRowValue(avgMetricName), label: getRowLabel(avgMetricName), color: this.settings.chartPrimaryColor });

    // top 10%
    data.push({ x: top10Label, y: getRowValue(top10MetricName), label: getRowLabel(top10MetricName), color: this.settings.chartPrimaryColor });

    // bottom 10%
    data.push({ x: bottom10Label, y: getRowValue(bottom10MetricName), label: getRowLabel(bottom10MetricName), color: this.settings.chartPrimaryColor });

    // selected
    var gtThanAvg = metricValue > getRowValue(avgMetricName);
    var up = (gtThanAvg && !this.settings.metricInverted) || (!gtThanAvg && this.settings.metricInverted);


    // selected
    if (getRowLabel('Level') != 'National') {
      data.push({ x: 'Selected', y: metricValue, label: getRowLabel(metricName), color: up ? 'green' : 'red' });
    }

    const chart = new Chart({
      chart: {
        type: chartType
      },
      colors: ['black'],
      title: {
        text: null
      },
      xAxis: {
        categories: data.map(val => val.x),
        title: {
          text: null
        },
        labels: {
          style: {
            color: '#000',
            fontWeight: 'bold',
            fontFamily: 'Helvetica Neue'
          }
        }
      },
      yAxis: {
        min: 0,
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        title: {
          text: null
        }
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            formatter: function () {
              const point = <any>this.point;
              return `<span style="font-weight: 500">${point.label}</span>`
            },
            style: {
              fontFamily: 'Helvetica Neue'
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      series: [<Highcharts.SeriesBarOptions>{
        data: data.map(val => ({ y: val.y, color: val.color, label: val.label }))
      }]
    });

    return chart;
  }

  getTrendArrowIconClass(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    let result = '';

    if (isTrendUp === null || trend === 0) {
      result = 'fa fa-horizontal-rule';
    } else if (trend > 0) {
      result = 'fa fa-chevron-circle-up chevron-icon';
    } else if (trend < 0) {
      result = 'fa fa-chevron-circle-down chevron-icon';
    } else {
      result = '';
    }

    return result;
  }

  getTrendIconColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);

    let result = '';

    if (isTrendUp === null) {
      result = 'bg-yellow';
    } else if (!isTrendUp) {
      result = 'bg-red';
    } else if (!!isTrendUp) {
      result = 'bg-green';
    } else {
      result = '';
    }

    return result;
  }

  getTrendColor(trend: number): string {
    const isTrendUp = this.isTrendUp(trend);
    
    let result = '';

    if (isTrendUp == null) {
      result = '';
    } else if (!!isTrendUp) {
      result = 'text-green';
    } else if (!isTrendUp) {
      result = 'text-red';
    } else {
      result = '';
    }

    return result;
  }

  isTrendUp(value: number) {
    if (value === 0 || value === undefined || value === null)
      return null;

    return value > 0
      ? this.settings.metricInverted ? false : true
      : this.settings.metricInverted ? true : false;
  }

  openHelpTextClicked(): void {
    this.helpTextService.openHelp(this.settings.helpTextKey, this.settings.helpTextTitle);
  }

  private processData(dataSet: Models.DataSet): void {
    let metricIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    let metricPreviousPeriodIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousPeriod);
    let metricPreviousYearIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricNamePreviousYear);
    let metricIndexPOP = dataSet.columns.findIndex(col => col.name === this.settings.metricNamePOP);
    let metricIndexYOY = dataSet.columns.findIndex(col => col.name === this.settings.metricNameYOY);

    this.metricValue = dataSet.rows[0][metricIndex].label;
    this.metricValuePreviousPeriod = dataSet.rows[0][metricPreviousPeriodIndex]?.label;
    this.metricValuePreviousYear = dataSet.rows[0][metricPreviousYearIndex]?.label;
    this.metricValuePOPValue = dataSet.rows[0][metricIndexPOP]?.value as number;
    this.metricValueYOYValue = dataSet.rows[0][metricIndexYOY]?.value as number;
    this.metricValuePOP = dataSet.rows[0][metricIndexPOP]?.label;
    this.metricValueYOY = dataSet.rows[0][metricIndexYOY]?.label;
    this.title = this.settings.title ?? dataSet.columns[metricIndex].displayName ?? dataSet.columns[metricIndex].name;
    this.titleIconClasses = this.settings.titleIconClasses;
    this.subtitleOneLabel = "Prev. Period:";
    this.subtitleOne = this.metricValuePreviousPeriod;

    this.chart = this.getBillboardChart(dataSet, this.settings.metricName, 'bar');
    // this.changeDetectorRef.markForCheck();
  }
}
