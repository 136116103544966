import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';
import { Enums } from '../../_shared/enums/enums';

export class RoutingConfig implements IRoutingConfig {

    public routes: IRouteConfig[] = [
    {
        id: 'logout',
        category: 'logout',
        iconClass: null,
        displayName: null,
        displayInNav: false,
        defaultRoute: '/logout',
        roles: undefined,
        children: [],
        routeData: {
            redirectOnLogout: true,
            content: [
                'You have been successfully logged out. Please close this browser window.'
            ]
        }
    },
    {
      id: 'website',
      category: 'website',
      default: true,
      defaultRoute: '/website/website-overview',
      displayName: 'Website',
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'website-overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        }
      ]
    }, {
      id: 'digAd',
      category: 'digAd',
      defaultRoute: '/digAd/dashboard',
      displayName: 'Digital Advertising',
      displayInNav: true,
      iconClass: 'fa fa-desktop',
      roles: undefined,
      children: [
        {
          parent: '/digAd',
          route: 'dashboard',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', 'System Analyst'],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst']
        }, {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children: 
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create/:id',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update/:id',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create/:id',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create/project/:queryConfigId',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update/:id',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create/:id',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update/:id',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/test',
              parent: 'query-configuration',
              route: 'test/:id',
              displayName: 'Test Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
            parent: '/management',
            route: 'resource-string',
            displayName: 'Resource Strings',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', "System Analyst"],
            children:[]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', "System Analyst"]
        }
      ]
    }
  ];
}
