<mat-card class="element-card stacked-two-kpi-metric-element">
  
  <!-- Loaded View -->
  <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
    <mat-card-content>
      <div style="display:flex;" fxLayout="column" fxLayoutAlign="flex-start center">
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="border-bottom: 1px solid #ddd; width: 100%;padding-bottom:10px">
          <div style="padding-right: 10px">
            <i [ngClass]="[getTrendArrowIconClass(primaryMetricTrend), getTrendIconColor(primaryMetricTrend)]" style="font-size: 40px;"></i>
          </div>
          <div fxLayout="column">
            <div style="font-size: 1rem;">{{primaryMetricLabel}}</div>
            <div style="font-size: 2rem; font-weight: 500; line-height: 1;">{{primaryMetricValue}}</div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;padding-top:10px">
          <div style="padding-right: 10px">
            <i [ngClass]="[getTrendArrowIconClass(secondaryMetricTrend), getTrendIconColor(secondaryMetricTrend)]" style="font-size: 40px;"></i>
          </div>
          <div fxLayout="column">
            <div style="font-size: 1rem;">{{secondaryMetricLabel}}</div>
            <div style="font-size: 2rem; font-weight: 500; line-height: 1;">{{secondaryMetricValue}}</div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </ng-container>

  <!-- Loading View with Pulse Animations -->
  <ng-template #loadingView>
    <mat-card-content>
      <div style="display: flex;" fxLayout="column" fxLayoutAlign="flex-start center">
        
        <!-- Primary Metric Placeholder -->
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px;">
          <div style="padding-right: 10px">
            <div class="icon-placeholder" syncPulse style="width: 40px; height: 40px; background-color: #e0e0e0; border-radius: 50%;"></div>
          </div>
          <div fxLayout="column">
            <div class="label-placeholder" syncPulse style="width: 100px; height: 20px; background-color: #e0e0e0; border-radius: 4px;"></div>
            <div class="value-placeholder" syncPulse style="width: 150px; height: 32px; background-color: #e0e0e0; border-radius: 4px; margin-top: 8px;"></div>
          </div>
        </div>

        <!-- Secondary Metric Placeholder -->
        <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%; padding-top: 10px;">
          <div style="padding-right: 10px">
            <div class="icon-placeholder" syncPulse style="width: 40px; height: 40px; background-color: #e0e0e0; border-radius: 50%;"></div>
          </div>
          <div fxLayout="column">
            <div class="label-placeholder" syncPulse style="width: 100px; height: 20px; background-color: #e0e0e0; border-radius: 4px;"></div>
            <div class="value-placeholder" syncPulse style="width: 150px; height: 32px; background-color: #e0e0e0; border-radius: 4px; margin-top: 8px;"></div>
          </div>
        </div>
        
      </div>
    </mat-card-content>
  </ng-template>

</mat-card>
