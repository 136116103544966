<div class="mat-elevation-z1 horizontal-bar-by-dimension-element">
    <!-- Loaded View -->
    <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
        <sd-card-header [title]="title" [iconClass]="titleIconClasses" [helpTextKey]="" [helpTextTitle]="" [locale]="">
        </sd-card-header>
        <div class="card-block">
            <div class="row labels">
                <span class="col-md-9 col-xl-10"><b>{{dimensionName}}:</b></span>
                <span *ngFor="let metric of columnMetrics"
                    class="col-md-3 col-xl-2"><b>{{getMetricLabel(metric)}}:</b></span>
            </div>
            <div class="row" *ngFor="let cc of dataRows">
                <div class="col-12">{{ cc.displayName }}</div>
                <div class="col-md-9 col-xl-10">
                    <div class="dimension-bar-table">
                        <table [ngStyle]="{ 'width': cc.barWidth }" [matTooltip]="cc.tooltipText"
                            [matTooltipPosition]="'above'">
                            <tr>
                                <td [ngStyle]="cc.barStyleOne"></td>
                                <td [ngStyle]="cc.barStyleTwo"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col-md-3 col-xl-2" *ngFor="let metric of cc.columnMetricDisplayValues">
                    {{ metric }}
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Loading View with Pulse Animations -->
    <ng-template #loadingView>
        <sd-card-header [title]="title" [iconClass]="titleIconClasses" [helpTextKey]="" [helpTextTitle]="" [locale]="">
        </sd-card-header>

        <div class="card-block">
            <div class="row labels">
                <span class="col-md-9 col-xl-10">
                    <div class="title-placeholder" syncPulse
                        style="height: 20px; width: 80%; background-color: #e0e0e0; border-radius: 4px;"></div>
                </span>
                <span *ngFor="let metric of columnMetrics" class="col-md-3 col-xl-2">
                    <div class="metric-placeholder" syncPulse
                        style="height: 20px; width: 60%; background-color: #e0e0e0; border-radius: 4px;"></div>
                </span>
            </div>

            <div class="row" *ngFor="let i of placeholderRows">
                <div class="col-12">
                    <div class="display-placeholder" syncPulse
                        style="height: 20px; width: 100%; background-color: #e0e0e0; border-radius: 4px;"></div>
                </div>
                <div class="col-md-9 col-xl-10">
                    <div class="dimension-bar-table">
                        <table style="width: 100%; background-color: #e0e0e0; border-radius: 4px;"
                            syncPulse>
                            <tr>
                                <td style="width: 50%; background-color: #cccccc; height: 12px;"></td>
                                <td style="width: 50%; background-color: #e0e0e0; height: 12px;"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col-md-3 col-xl-2" *ngFor="let metric of placeholderMetrics">
                    <div class="metric-placeholder" syncPulse
                        style="height: 20px; width: 60%; background-color: #e0e0e0; border-radius: 4px;"></div>
                </div>
            </div>
        </div>
    </ng-template>
</div>