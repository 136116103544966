<mat-card class="element-card stacked-dual-metric-element">
  
  <!-- Loaded View -->
  <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
    <mat-card-title>
      <i class="fa pad-right" [ngClass]="titleIconClasses"></i><b>{{ title }}</b>
      <div class="pull-right ellipses-dropdown" *ngIf="settings.helpTextKey">
        <button
          title="options"
          mat-button
          class="card-options-button"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"
        >
          <i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            style="outline: none"
            (click)="openHelpTextClicked()"
          >
            {{ "Help" | translate : locale | async }}
          </button>
        </mat-menu>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div
        style="display: flex; margin-left:20px"
        fxLayout="column"
        fxLayoutAlign="flex-start center"
      >
        <ng-container *ngFor="let metric of metrics; let i = index">
          <div
            fxLayout="row"
            fxLayoutAlign="flex-start center"
            style="margin-right: 10px"
            [ngStyle]="{
              'border-bottom': i === 0 ? '1px solid #ddd' : 'none',
              width: '100%',
              padding: i === 0 ? '0 0 10px 0' : '10px 0 0 0'
            }"
          >
            <div style="padding-right: 10px">
              <i
                [ngClass]="[
                  getTrendArrowIconClass(metric.trend),
                  getTrendIconColor(metric.trend)
                ]"
                style="font-size: 40px"
              ></i>
            </div>
            <div fxLayout="column">
              <div style="font-size: 1rem">{{ metric.label }}</div>
              <div style="font-size: 2rem; font-weight: 500; line-height: 1">
                {{ metric.value }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </ng-container>

  <!-- Loading View with Pulse Animations -->
  <ng-template #loadingView>
    <mat-card-title>
      <div class="title-placeholder" syncPulse style="height: 24px; width: 150px; background-color: #e0e0e0; border-radius: 4px;"></div>
    </mat-card-title>

    <mat-card-content>
      <div style="display: flex; margin-left:20px" fxLayout="column" fxLayoutAlign="flex-start center">
        <ng-container *ngFor="let i of placeholderMetrics">
          <div
            fxLayout="row"
            fxLayoutAlign="flex-start center"
            style="margin-right: 10px; width: 100%; border-bottom: 1px solid #ddd; padding: 0 0 10px 0;"
          >
            <div style="padding-right: 10px">
              <div class="icon-placeholder" syncPulse style="width: 40px; height: 40px; background-color: #e0e0e0; border-radius: 50%;"></div>
            </div>
            <div fxLayout="column">
              <div class="label-placeholder" syncPulse style="width: 100px; height: 20px; background-color: #e0e0e0; border-radius: 4px;"></div>
              <div class="value-placeholder" syncPulse style="width: 150px; height: 32px; background-color: #e0e0e0; border-radius: 4px; margin-top: 8px;"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </ng-template>

</mat-card>
