<div class="column-chart">
  <div style="display:flex;" fxLayout="column" fxLayoutAlign="space-around center">
    <div style="width: 100%;">
      <div *ngIf="isDataSetLoaded$ | async; else loading">
        <div [chart]="currentChart"></div>
      </div>
      <ng-template #loading>
        <!-- Placeholder with pulsar animation -->
        <div class="chart-placeholder" syncPulse>
          <!-- You can add SVG or other elements here to simulate a chart placeholder -->
        </div>
      </ng-template>
    </div>
  </div>
</div>