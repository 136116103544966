import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import * as Models from '../../../models/models-index';
import { BehaviorSubject, map, startWith } from 'rxjs';

@Component({
  selector: 'horizontal-bar-by-dimension',
  templateUrl: './horizontal-bar-by-dimension.component.html',
  styleUrls: ['../panel-elements.scss','./horizontal-bar-by-dimension.scss'],
})
export class HorizontalBarByDimensionComponent implements Models.ElementComponent, OnInit {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: Models.ElementSettings;

  // @Input() locale: string;
  @Output() openHelpText = new EventEmitter();

  dataRows: any[] = [];
  columnMetrics;
  dimensionName;
  title;
  titleIconClasses;
  
  placeholderRows = Array(4).fill(0); // Placeholder for 4 rows
  placeholderMetrics = Array(2).fill(0); // Placeholder for 2 metrics per row

  constructor() {
  }

  ngOnInit() {
  }

  configureDataRows(dataSet: Models.DataSet, settings: Models.ElementSettings) {
    let dataRows = [];
    let dimensionIndex = dataSet.columns.findIndex(col => col.name === settings.dimensionName);
    let colorIndex = dataSet.columns.findIndex(col => col.name === settings.colorPropertyName);
    let primaryMetricIndex = dataSet.columns.findIndex(col => col.name === settings.metricName);
    let secondaryMetricIndex = dataSet.columns.findIndex(col => col.name === settings.secondaryMetricName);
    let primaryMetricValues = dataSet.rows.map(r => r[primaryMetricIndex].value);
    let secondaryMetricValues = dataSet.rows.map(r => r[secondaryMetricIndex].value);

    dataSet.rows.forEach(row => {
      let columnMetricDisplayValues = [];
      /*
       * This needs to be rewritten to do dataset based discovery
       */
      // settings.columnMetrics.forEach(cm => {
      //   const index = dataSet.columns.findIndex(col => col.name === cm);
      //   columnMetricDisplayValues.push(row[index].label);
      // })
      dataRows.push(
        {
          displayName: row[dimensionIndex].label,
          columnMetricDisplayValues: columnMetricDisplayValues,
          barWidth: this.getChannelWidth(primaryMetricValues, row[primaryMetricIndex].value),
          tooltipText: `${dataSet.columns[primaryMetricIndex].displayName}: ${row[primaryMetricIndex].label} — ${dataSet.columns[secondaryMetricIndex].displayName}: ${row[secondaryMetricIndex].label}`,
          barStyleOne: this.getPrimaryChannelStyle(row[colorIndex].value, row[primaryMetricIndex].value, row[secondaryMetricIndex].value, 1),
          barStyleTwo: this.getSecondaryChannelStyle(row[colorIndex].value, row[primaryMetricIndex].value, row[secondaryMetricIndex].value, 0.5)
        }
      )
    });

    return dataRows;
  }

  getChannelWidth(metricValues: any[], currentMetricValue: any): string {
    const maxValue = Math.max(...metricValues, 0);
    return '' + currentMetricValue / maxValue * 100 + '%';
  }

  getSecondaryChannelMetricWidth(primaryMetricValue: number, secondaryMetricValue: number): string {
    return '' + secondaryMetricValue / primaryMetricValue * 100 + '%';
  }

  getPrimaryChannelStyle(color, primaryMetricValue, secondaryMetricValue, opacity: number) {
    return {
      'border': '1px solid white',
      'background-color': color,
      'width': 'calc(100% - ' + this.getSecondaryChannelMetricWidth(primaryMetricValue, secondaryMetricValue) + ')',
      'opacity': opacity
    };
  }

  getSecondaryChannelStyle(color, primaryMetricValue, secondaryMetricValue, opacity: number) {
    return {
      'border': '1px solid white',
      'background-color': color,
      'width': this.getSecondaryChannelMetricWidth(primaryMetricValue, secondaryMetricValue),
      'opacity': opacity
    };
  }

  getChannelTooltipText(metric: any, primaryMetricPropertyName: string, primaryMetricLabel: string, secondaryMetricPropertyName: string, secondaryMetricLabel: string): string {
    return `${primaryMetricLabel}: ${metric[primaryMetricPropertyName]} — ${secondaryMetricLabel}: ${metric[secondaryMetricPropertyName]}`;
  }

  getMetricLabel(metricPropertyName: string) {
    return this.dataSetSubject.value.columns.find(ds => ds.name === metricPropertyName).displayName;
  }

  getTrendArrowIconClass(trend: number): string {
    if (trend === 0) {
      return 'fa fa-arrows-h';
    } else if (trend < 0) {
      return 'fa fa-level-down';
    } else {
      return 'fa fa-level-up';
    }
  }

  getTrendColor(trend: number): string {
    if (!trend) {
      return '';
    } else if (trend >= 0) {
      return 'text-green';
    } else {
      return 'text-red';
    }
  }

  openHelpTextClicked(): void {
    this.openHelpText.emit();
  }

  private processData(dataSet: Models.DataSet): void {
    this.dimensionName = dataSet.columns.find(ds => ds.name === this.settings.dimensionName).displayName;
    //this.columnMetrics = this.settings.columnMetrics;
    this.title = this.settings.title;
    this.titleIconClasses = this.settings.titleIconClasses;
    this.dataRows = this.configureDataRows(this.dataSet, this.settings);
  }
}
