import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { ElementComponent } from '../../../models/models-index';
import { PanelElementResolverService } from '../panel-element-resolver.service';

@Component({
  selector: 'ghost-panel-full-width',
  templateUrl: './ghost-panel-full-width.component.html',
  styleUrls: ['./ghost-panel-full-width.component.scss']
})
export class GhostPanelFullWidthComponent implements OnInit, Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panelConfig: Models.Panel;
  @Input() rowPanelId: string;

  panelTitle: string;
  
  private _dataSets: Models.DataSet[] = [];

  private componentRefs: ElementComponent[] = [];

  @Input()
  set dataSets(value: Models.DataSet[]) {
    this._dataSets = value;
    if (this._dataSets?.length && this.panelConfig) {
      // Datasets are now available, update the components
      this.updateComponentsWithDataSets();
      return;
    }

    // If datasets are not available, start loading animation
    this.componentRefs.forEach(component => {
      component.dataSet = null;
    });
  }

  get dataSets(): Models.DataSet[] {
    return this._dataSets;
  }

  constructor(
    private resolverService: PanelElementResolverService,
    private renderer2: Renderer2,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Generate elements immediately
    this.generateElements();
  }

  generateElements() {
    if (!this.panelConfig) {
      // Prevent element generation if panelConfig is not available
      return;
    }

    const config = this.panelConfig?.configurations[0];

    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    this.componentRefs = []; // Clear any existing component references

    config?.elements.forEach(element => {
      // Initially, dataset might not be available
      const dataset = this._dataSets.find(ds => ds.name === element.settings.dataSet) || null;
      const componentFactory = this.resolverService.resolveElementComponent(element.type);
      const componentRef = viewContainerRef.createComponent<ElementComponent>(componentFactory);
      componentRef.instance.dataSet = dataset; // dataset may be null at this point
      componentRef.instance.settings = element.settings;
      this.renderer2.addClass(componentRef.location.nativeElement, 'ghost-panel-element');
      this.renderer2.setAttribute(componentRef.location.nativeElement, 'style', `width:100%; min-width: 0px;`);

      // Keep a reference to the component instance
      this.componentRefs.push(componentRef.instance);
    });
  }

  private updateComponentsWithDataSets() {
    if (!this.componentRefs || this.componentRefs.length === 0) {
      // If components are not yet created, generate them
      this.generateElements();
      return;
    }

    const config = this.panelConfig?.configurations[0];

    config?.elements.forEach((element, index) => {
      const dataset = this._dataSets.find(ds => ds.name === element.settings.dataSet);
      const componentInstance = this.componentRefs[index];
      if (componentInstance) {
        componentInstance.dataSet = dataset;
      }
    });
  }
}
