import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, iif, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { ElementComponent } from '../../../models/models-index';
import { ReportsService } from '../../../services/api/reports.service';
import { PanelElementResolverService } from '../panel-element-resolver.service';
import * as SharedServices from '../../../services/services-index';
import { ChartService } from '../../chart/chart.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../_store/app-state.model';
import { AppSelectors } from '../../../../_store/selector-types';
import { is } from 'core-js/core/object';

const clone = require('rfdc/default');

type PanelMetric = { name: string, displayName: string };
type SelectedConfiguration = { config: Models.PanelConfiguration, selectedMetrics: string[] };

@Component({
  selector: 'indexed-visualization-panel',
  templateUrl: './indexed-visualization-panel.component.html',
  styleUrls: ['./indexed-visualization-panel.component.scss']
})
export class IndexedVisualizationPanelComponent implements OnInit, OnDestroy, Models.PanelComponent {

  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  @Input() panelConfig: Models.Panel;
  private _dataSets: Models.DataSet[] = [];
  private componentInstance: ElementComponent;

  @Input()
  set dataSets(value: Models.DataSet[]) {
    this._dataSets = value;
    if(this._dataSets?.length > 0)
    {
      this.assignDataToElement();

      return;
    }

    if (this.componentInstance) {
      // set current element to loading state
      this.componentInstance.dataSet = null;

      // if filters change, make sure it changes element to the default one.
      if(this.selectedConfiguration.config.elements[0].name != this.panelConfigs[0].elements[0].name) {
        this.renderDefaultElement();
      }

      return;
    }
  }
  get dataSets(): Models.DataSet[] {
    return this._dataSets;
  }
  
  @Input() rowPanelId: string;

  @Output() expandClicked = new EventEmitter<boolean>();

  panelTitle;
  headerConfig;
  subscriptions: Subscription[] = [];
  get panelConfigs() { return this.panelConfig.configurations }
  selectedConfiguration$ = new BehaviorSubject<SelectedConfiguration>(null);
  get selectedConfiguration() { return this.selectedConfiguration$.value; }

  panelMetrics$ = new BehaviorSubject<PanelMetric[]>([]);
  get panelMetrics() { return this.panelMetrics$.value; }
  selectedMetrics: string[] = [];
  translationService: Models.ITranslationService;
  locale: string;
  destroySubject = new Subject<void>();
  destroyed$ = this.destroySubject.asObservable();


  requestModel: Models.ReportRequestModel = {
    orderBy: '',
    orderAscending: false,
    reportType: 'data-panel-load',
    filters: {
      startDate: new Date(2018, 7, 1),
      endDate: new Date(2018, 8, 1),
      previousStartDate: new Date(),
      previousEndDate: new Date(),
      orgCode5: null,
      orgCode4: null,
      orgCode3: null,
      orgCode2: null,
      orgCode1: null,
      dealerCode: null,
      deviceTypeId: null,
      orgLookupTypeId: 1,
    }
  };

  constructor(
    private store$: Store<AppState>,
    private resolverService: PanelElementResolverService,
    private chartService: ChartService,
    private renderer2: Renderer2,
    private reportService: ReportsService,
    private filterService: SharedServices.FilterService,
    private helpTextService: SharedServices.HelpTextService,
    private localeService: SharedServices.LocaleService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => this.locale = loc),
      this.generateElements().subscribe(),
    );

    this.renderDefaultElement();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  generateElements() {
    return this.selectedConfiguration$
      .pipe(
        filter(selectedConfiguration => !!selectedConfiguration?.config),
        map(selectedConfiguration => {
          const { config, selectedMetrics } = selectedConfiguration;
          const element = config.elements[0];
          this.panelTitle = config.label ?? config.name;

          this.selectedMetrics = selectedMetrics ?? [];

          const viewContainerRef = this.dynamicComponent.viewContainerRef;
          viewContainerRef.clear();

          const componentFactory = this.resolverService.resolveElementComponent(element.type)
          const componentRef = viewContainerRef.createComponent<ElementComponent>(componentFactory);
          this.componentInstance = componentRef.instance;
          this.componentInstance.settings = clone(element.settings);
          this.componentInstance.selectedMetric = this.selectedMetrics[0];
          this.componentInstance.selectedMetrics = this.selectedMetrics;
          componentRef.instance.panelConfiguration = config;
          this.renderer2.addClass(componentRef.location.nativeElement, 'indexed-visualization-panel-element');
          
          const dataSetsLoaded = this.dataSets && this.dataSets.length > 0;
          if (dataSetsLoaded) {
            this.assignDataToElement();
            return;
          }

          // if data sets not loaded, just render empty elements until data is available
          // this.chartService.reflowCharts();
          this.changeDetector.detectChanges();
        })
      )
  }

  configureMetrics(dataSet: Models.DataSet) {
    const columns = dataSet.columnSets.length > 0
      ? dataSet.columns.filter(c => dataSet.columnSets[0].columnNames.includes(c.name))
      : dataSet.columns;

    const metrics = columns.map(c => ({ name: c.name, displayName: c.displayName }));
    this.panelMetrics$.next(metrics);
  }

  loadDataSet(dataSetName: string): Observable<Models.DataSet> {
    // Check if the dataset with the given name exists in this.dataSets
    const existingDataSet = this.dataSets.find(
      (dataSet) => dataSet.name === dataSetName
    );

    // If the dataset exists, return it as an Observable
    if (existingDataSet) {
      return of(existingDataSet);
    }

    // this.showReportFilterBar$ = this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
    //   map(routeData => {
    //     return routeData.useV5Filters;
    //   })
    // );

    return this.store$.select(AppSelectors.selectCurrentRouteData).pipe(
      switchMap(routeData =>
        iif(
          () => !!routeData.useV5Filters,
          this.getReportViewDataSet(routeData.reportName, dataSetName),
          this.getLegacyDataSet(dataSetName)
        )
      )
    );
  }

  getReportViewDataSet(reportName: string, dataSetName: string): Observable<Models.DataSet> {
    return this.filterService.getReportViewFilterRequestModel(reportName).pipe(
      takeUntil(this.destroyed$),
      map(requestModel => ({ ...requestModel, dataSets: [dataSetName] })),
      switchMap(requestModel => this.reportService.getDataSets(requestModel)),
      map((dataSets: Models.DataSet[]) => {
        if (!dataSets || dataSets.length == 0)
          throw new Error('No data sets returned from the API');

        this.dataSets.push(dataSets[0]);

        return dataSets[0];
      }),
      tap({
        error: err => {
          appInsights.trackException(err);
          // Rethrow the error to ensure it's propagated up the stream
          throw err;
        }
      }),
      catchError(err => {
        // Handle the error and return a safe value or an empty Observable
        // Example: return of({} as Models.DataSet);
        // Or rethrow the error if you want to handle it further up the chain
        return throwError(() => err);
      })
    );
  }

  getLegacyDataSet(dataSetName: string): Observable<Models.DataSet> {
    return this.filterService.filter$.pipe(
      map(updatedFilter => {
        const requestModel = { ...this.requestModel, filters: { ...updatedFilter }, dataSets: [dataSetName] };
        return requestModel;
      }),
      switchMap(requestModel => this.reportService.getDataSets(requestModel)),
      map((dataSets: Models.DataSet[]) => {
        if (!dataSets || dataSets.length == 0)
          throw new Error('No data sets returned from the API');

        this.dataSets.push(dataSets[0]);

        return dataSets[0];
      })
    );
  }

  // translateDataSet(ds: Models.DataSet) {
  //   ds.columns.forEach(c => {
  //     const displayName = c.displayName ?? c.name
  //     c.displayName = c.type === 'metric'
  //       ? this.translationService?.getMetricNameTranslation(c.name, displayName, this.locale) ?? displayName
  //       : this.translationService?.getLabelTranslation(displayName, this.locale) ?? displayName
  //   });
  //   ds.rows.forEach(r => {
  //     r.forEach(m => {
  //       if (m.label == undefined && !!m.value) {
  //         const displayName = <string>m.value;
  //         m.value = this.translationService?.getChartDimensions(displayName, this.locale) ?? displayName
  //       }
  //     })
  //   })
  //   return ds;
  // }

  indexChanged($event) {
    const changedPanelConfig = this.panelConfigs.find(c => c.name === $event.value.name);
    this.selectedConfiguration$.next({ config: changedPanelConfig, selectedMetrics: changedPanelConfig.elements[0].settings.defaultMetrics });
  }

  selectedMetricChanged($event, index: number) {
    const updatedMetrics = [...this.selectedMetrics];
    updatedMetrics[index] = $event.value;

    this.selectedConfiguration$.next({ config: this.selectedConfiguration.config, selectedMetrics: updatedMetrics });
  }

  expandButtonClicked(evt) {
    this.expandClicked.emit(evt)
  }

  openHelpTextClicked(): void {
    this.helpTextService.openHelp(this.selectedConfiguration.config.helpTextKey, this.selectedConfiguration.config.helpTextTitle);
  }

  toggleExpanded(evt) {
    const element = document.getElementById(this.rowPanelId);
    element.classList.toggle('wrap-row-panel');
    this.chartService.reflowCharts();
    // window.setTimeout(() => {
    //   element.scrollIntoView();
    // }, 300)
  }

  private assignDataToElement(): void {
    if (!this.componentInstance || !this.selectedConfiguration || !this.selectedConfiguration.config) {
      return;
    }

    const element = this.selectedConfiguration.config.elements[0];
    const dataSetName = element.settings.dataSet;

    // Try to find dataSet in this.dataSets
    const dataSet = this.dataSets.find((ds) => ds.name === dataSetName);
    if (dataSet) {
      this.assignDataToGeneratedElement(dataSet, element);
    } else {
      // DataSet not found, attempt to load it
      this.loadDataSet(dataSetName)
      .pipe(first())
      .subscribe((dataSetResponse) => {
        this.assignDataToGeneratedElement(dataSetResponse, element);
      });
    }
  }

  assignDataToGeneratedElement(dataset: Models.DataSet, element: Models.Element) {
    this.configureMetrics(dataset);
    if (!this.selectedConfiguration.config.disableMetricSelection && this.selectedMetrics.length === 0) {
      this.selectedMetrics.push(dataset.columns.find(c => c.type === 'metric')?.name);
      this.componentInstance.selectedMetric = this.selectedMetrics[0];
      this.componentInstance.selectedMetrics = this.selectedMetrics;
    }
    this.componentInstance.dataSet = dataset;
  }

  isExpectedDataSet() {
    const element = this.selectedConfiguration.config.elements[0];
    const dataSetName = element.settings.dataSet;
    const isExpectedDataSet = !this.componentInstance.dataSet || this.componentInstance.dataSet?.name === dataSetName;
    
    return isExpectedDataSet;
  }

  renderDefaultElement(): void {
    this.selectedConfiguration$.next({ config: this.panelConfigs[0], selectedMetrics: this.panelConfigs[0].elements[0].settings.defaultMetrics });
  }
}
