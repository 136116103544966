<div class="column-chart">
  <div
    style="display: flex; height: 100%"
    fxLayout="column"
    fxLayoutAlign="space-around center"
  >
    <div style="width: 100%">
      <div *ngIf="isDataSetLoaded$ | async; else loading">
        <div [chart]="currentChart"></div>
      </div>
      <ng-template #loading>
        <div [chart]="placeholderChart" syncPulse></div>
      </ng-template>
    </div>
  </div>
</div>
