<mat-card class="element-card kpi-pie-chart-element">
    <mat-card-content>
      <sd-card-header [title]="title"
                      [iconClass]="iconClass"
                      [helpTextKey]="helpTextKey"
                      [helpTextTitle]="helpTextTitle"
                      [locale]="locale">
      </sd-card-header>
      <div class="card-block pie-chart">
        <div style="display:flex;" fxLayout="column" fxLayoutAlign="space-around center">
          <div style="width: 100%;">
            <div *ngIf="isDataSetLoaded$ | async; else loading">
              <div [chart]="currentChart"></div>
            </div>
            <ng-template #loading>
              <div [chart]="placeholderChart" syncPulse></div>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  