<div class="data-table-wrapper" [ngClass]="[expanded ? 'fill-canvas' : '', 'sd-infinite-scroll-xy', 'first-column-pinned']">
  <div *ngIf="isDataSetLoaded$ | async; else loading">
      <table mat-table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let column of columns; trackBy: customTrackBy" [matColumnDef]="column.columnDef"
      [sticky]="column.columnDef === 'entity'" [stickyEnd]="column.stickyEnd">

      <!-- Column definition for the entity (first column) -->
      <div *ngIf="column.columnDef === 'entity'" [ngClass]="calculateClasses(column)">
        <th mat-header-cell *matHeaderCellDef
          [ngClass]="calculateClasses(column, ['mat-table-sticky-border-elem-left'])">
          <ng-container *ngIf="column.sortable; else nonSortableHeader">
            <span mat-sort-header="{{column.columnDef}}">{{column.header}}</span>
          </ng-container>
          <ng-template #nonSortableHeader>
            <span>{{column.header}}</span>
          </ng-template>
        </th>
        <td mat-cell *matCellDef="let row"
          [ngStyle]="{ 'background-color': getBackgroundColor(row, column, heatmapEnabled) }"
          [ngClass]="calculateClasses(column, [row.entityType, 'sticky-column', 'data-table-header'])">
          <i *ngIf="(row.expandable)" (click)="toggleChildRows(row)"
            [ngClass]="[row.showChildren ? 'fa-caret-down' : 'fa-caret-right', 'fa']"
            style="cursor: pointer;">&nbsp;</i>
          &nbsp;
          <ng-container *ngIf="!column.canDrillthrough || (column.canDrillthrough && !column.canDrillthrough(row))"
            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ row['displayName'] ?? row['entity'] | translate: locale | async | formatter: column.formatter }}
          </ng-container>
          <a *ngIf="column.canDrillthrough && column.canDrillthrough(row)" (click)="updateReport(row)">
            {{ row['displayName'] ?? row['entity'] | translate: locale | async | formatter: column.formatter }}
          </a>
        </td>
      </div>

      <!-- Column definition for non-entity columns -->
      <div *ngIf="(column.columnDef !== 'entity')" [ngClass]="calculateClasses(column)">
        <th mat-header-cell *matHeaderCellDef
          [ngClass]="calculateClasses(column, ['first-data-column', 'data-table-header'])">
          <ng-container *ngIf="column.sortable; else nonSortableHeader">
            <span mat-sort-header="{{column.columnDef}}">{{column.header}}</span>
          </ng-container>
          <ng-template #nonSortableHeader>
            <span>{{column.header}}</span>
          </ng-template>
        </th>
        <td mat-cell *matCellDef="let row"
          [ngStyle]="{ 'background-color': getBackgroundColor(row, column, heatmapEnabled) }"
          [ngClass]="row[column.columnDef + '_classes']">
          {{ row[column.columnDef] | formatter: column.formatter }}
        </td>
      </div>
    </ng-container>

    <!-- Table header and row definitions -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="data-table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="[!row.show ? 'hideRow' : '', (row.entityType + '-row')]"></tr>
  </table>
  </div>

  <!-- Skeleton Loader -->
  <ng-template #loading>
    <table mat-table [dataSource]="placeholderData">
      <!-- Define skeleton columns -->
      <ng-container *ngFor="let col of skeletonColumns" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef>
          <div class="skeleton-text" syncPulse style="width: 80%; height: 16px;"></div>
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="skeleton-text" syncPulse style="width: 100%; height: 16px;"></div>
        </td>
      </ng-container>

      <!-- Header and Skeleton Rows -->
      <tr mat-header-row *matHeaderRowDef="skeletonColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: skeletonColumns;" class="skeleton-row"></tr>
    </table>
  </ng-template>
</div>
