import { Component, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Models from '../../models/models-index';
import * as SharedServices from '../../services/services-index';
import { ReportsService } from '../../services/api/api-index';
import { PanelElementResolverService } from '../../components/panels/panel-element-resolver.service';
import { DynamicComponentDirective } from '../../directives/dynamic-component.directive';
import { RowPanelComponent } from '../panels/row-panel/row-panel.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription, combineLatest, forkJoin, merge, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SharedHelpTextModalComponent } from '../../modals/modals-index';
import { FilterActions } from '../../filter/store/action-types';
import { Store } from '@ngrx/store';
import { AppState } from '../../../_store/app-state.model';
import { AppSelectors } from '../../../_store/selector-types';
import { FilterSelectors } from '../../filter/store';


@Component({
  selector: 'report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  destroySubject = new Subject<void>();
  destroyed$ = this.destroySubject.asObservable();
  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;
  requestModel: Models.ReportRequestModel;
  private componentRefsMap: Map<string, ComponentRef<RowPanelComponent | Models.PanelComponent>> = new Map();
  
  constructor(
    private resolverService: PanelElementResolverService,
    public spinner: SharedServices.SpinnerService,
    private reportService: ReportsService,
    private filterService: SharedServices.FilterService,
    private dialog: MatDialog,
    private translationService: SharedServices.TranslationService,
    private reportViewConfigurationService: SharedServices.ReportViewConfigurationService,
    private store$: Store<AppState>
  ) {

  }

  ngOnInit(): void {
    this.store$.select(AppSelectors.selectCurrentRouteData)
      .pipe(
        switchMap(routeData =>
          this.reportViewConfigurationService.getByName(routeData.reportName).pipe(
            tap(reportViewConfig => {
              this.filterService.setActivePageTitle(reportViewConfig.title);
              this.generatePanels(reportViewConfig);
            }),
            switchMap(reportViewConfig =>
              this.getReportViewFilters(routeData.reportName).pipe(
                map(filters => ({ filters, reportViewConfig, routeData })),
                switchMap(({ reportViewConfig, routeData }) =>
                  this.filterService.getReportViewFilterRequestModel(routeData.reportName)
                    .pipe(
                      map(requestModel => ({ reportViewConfig, requestModel }))
                    )
                ),
                tap(({ requestModel }) => {
                  this.loadDataSets(requestModel);
                })
              )
            )
          )
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }  

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  getReportViewFilters(reportName: string): Observable<Models.ReportViewFilter[]> {
    return this.reportService.getReportFilterNames(reportName).pipe(
      tap(config => this.store$.dispatch(FilterActions.updateReportViewFilterConfiguration({ config: config }))),
      switchMap(config => this.store$.select(FilterSelectors.selectReportViewFiltersForReport({ reportName })).pipe(
        filter(filters => config.filterNames.every(filterName => filters.some(f => f.name === filterName))
      ))
    ));
  }
  
  generatePanels(reportViewConfiguration: Models.ReportViewConfigurationResponse) {
    this.filterService.setActivePageTitle(reportViewConfiguration.title);
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    this.componentRefsMap.clear();

    // get the max row number from the panels
    const maxRow = Math.max(...reportViewConfiguration.panels.map(panel => panel.row));

    if (maxRow > 0) {
      // create a row for each row number
      for (let i = 1; i <= maxRow; i++) {
        const rowPanels = reportViewConfiguration.panels.filter(panel => panel.row === i)
        const componentFactory = this.resolverService.resolvePanelComponent('row-panel');
        const componentRef = viewContainerRef.createComponent(RowPanelComponent);
        componentRef.instance.rowIndex = i;
        componentRef.instance.panels = rowPanels;
        componentRef.instance.translationService = this.translationService;

        // Store the componentRef with row number as a key
        this.componentRefsMap.set(i.toString(), componentRef);
      }
    } else {
      reportViewConfiguration.panels.forEach(panel => {
        const componentFactory = this.resolverService.resolvePanelComponent(panel.type);
        const componentRef = viewContainerRef.createComponent<Models.PanelComponent>(componentFactory);
        componentRef.instance.panelConfig = panel;
        componentRef.instance.translationService = this.translationService;

        // Store the componentRef with panel name as a key
        this.componentRefsMap.set(panel.name, componentRef);
      });
    }
  }

  private loadDataSets(requestModel: Models.ReportRequestModel) {
    this.componentRefsMap.forEach((componentRef, key) => {
      // report data changed so let's inform panels to start pulsar animation
      // until data is fully loaded
      componentRef.instance.dataSets = [];
      if (componentRef.componentType === RowPanelComponent) {
        const casted = componentRef.instance as RowPanelComponent;
        const datasetsToLoad = this.getDataSetsToLoad(casted.panels);
        const updatedRequestModel: Models.ReportRequestModel = {
          ...requestModel,
          dataSets: datasetsToLoad
        };

        // Fetch datasets
        this.reportService.getDataSets(updatedRequestModel)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(datasets => {
            // Find the corresponding componentRef
            const componentRef = this.componentRefsMap.get(key);

            componentRef.instance.dataSets = datasets;
          });
      } else {
        const casted = componentRef.instance as Models.PanelComponent;

        const datasetsToLoad = this.getDataSetsToLoad([casted.panelConfig]);
        const updatedRequestModel: Models.ReportRequestModel = {
          ...requestModel,
          dataSets: datasetsToLoad
        };

        // Fetch datasets
        this.reportService.getDataSets(updatedRequestModel)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(datasets => {
            // Find the corresponding componentRef
            const componentRef = this.componentRefsMap.get(casted.panelConfig.name);

            componentRef.instance.dataSets = datasets;
          });
      }
    });
  }  

  // help
  openHelp(panelConfig: Models.Panel) {
    this.dialog.open(SharedHelpTextModalComponent, {
      width: '50vw',
      data: {
        title: panelConfig.configurations[0].helpTextTitle,
        name: panelConfig.configurations[0].helpTextKey
      }
    });
  }

  getInitialRequestModel(routeData): Models.ReportRequestModel {
    return {
      orderBy: '',
      orderAscending: false,
      reportType: routeData.reportName,
      filters: {
        startDate: new Date(),
        endDate: new Date()
      }
    };
  }

  private getDataSetsToLoad(rowPanels: Models.Panel[]): string[] {
    const dataSetsToLoad = Array.from(
      new Set(
        rowPanels
          .flatMap(panel => panel.configurations[0]) // Get default configuration from all panels
          .flatMap(config => config.elements)        // Get all elements from default configurations
          .map(element => element.settings?.dataSet) // Get dataset from each element
          .filter(dataSet => dataSet !== undefined)  // Filter out undefined datasets
      )
    );

    return dataSetsToLoad;
  }
}
