<mat-card
  class="mat-elevation-z1 element-card kpi-dual-metric-detailed-element"
  style="background-color: white; color: black"
>
</mat-card>

<mat-card class="mat-elevation-z1 element-card kpi-dual-metric-detailed-element" style="background-color: white; color: black">
  
  <!-- Loaded View -->
  <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
    <mat-card-title>
      <i class="fa pad-right" [ngClass]="titleIconClasses"></i><b>{{ title }}</b>
      <div class="pull-right ellipses-dropdown" *ngIf="settings.helpTextKey">
        <button
          title="options"
          mat-button
          class="card-options-button"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="menu"
        >
          <i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            style="outline: none"
            (click)="openHelpTextClicked()"
          >
            {{ "Help" | translate : locale | async }}
          </button>
        </mat-menu>
      </div>
    </mat-card-title>
    <mat-card-content
      class="card-block target-card billboard"
      style="margin-bottom: 0px"
    >
      <div class="metrics-container">
        <ng-container *ngFor="let metric of metrics; let i = index">
        <div class="metric-item">
          <div class="metric-content">
            <b class="metric-value">
              {{ metric.value }}
            </b>
            <i
              [ngClass]="[
                getTrendArrowIconClass(metric),
                getTrendIconColor(metric)
              ]"
            ></i>
          </div>
  
          <div *ngIf="metric.subtitleOneValue" class="subtitle">
            <span class="subtitle-label">{{ metric.subtitleOneLabel }}</span>
            <span class="subtitle-value">{{ metric.subtitleOneValue }}</span>
          </div>
        </div>
        <div *ngIf="i != (metrics.length - 1)" class="metric-item-separator"></div>
      </ng-container>
      </div>
    </mat-card-content>
  </ng-container>

  <!-- Loading View with Pulse Animations -->
  <ng-template #loadingView>
    <mat-card-title>
      <div class="title-placeholder" syncPulse style="height: 24px; width: 150px; background-color: #e0e0e0; border-radius: 4px;"></div>
    </mat-card-title>

    <mat-card-content class="card-block target-card billboard" style="margin-bottom: 0px">
      <div class="metrics-container">
        <ng-container *ngFor="let i of placeholderMetrics">
          <div class="metric-item">
            <div class="metric-content">
              <div class="value-placeholder" syncPulse style="height: 40px; width: 80%; background-color: #e0e0e0; border-radius: 4px;"></div>
              <div class="icon-placeholder" syncPulse style="display: inline-block; width: 20px; height: 20px; background-color: #e0e0e0; border-radius: 50%; margin-left: 10px;"></div>
            </div>

            <div class="subtitle">
              <ng-container *ngFor="let j of placeholderSubtitles">
                <div class="subtitle-placeholder" syncPulse style="height: 20px; width: 60%; background-color: #e0e0e0; border-radius: 4px;"></div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="i != placeholderMetrics.length - 1" class="metric-item-separator"></div>
        </ng-container>
      </div>
    </mat-card-content>
  </ng-template>
</mat-card>