<div style="height: 400px; border-bottom: 1px; overflow-y: auto;">
  <table class="mat-elevation-z8 provider-kpi-table" style="width: 100%; border-collapse: collapse !important;">

    <!-- Loaded View -->
    <ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
      <thead>
        <th *ngFor="let col of columns; let idx = index;" [class.alt-background]="idx % 2 != 0" style="min-width: 200px;" class="sticky-header">{{col.label}}</th>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <td style="text-align:center; border-bottom: 1px solid #d8d8d8;">
            <img [src]="row.code" [alt]="row.name" style="display: block; margin: auto;" />
          </td>
          <td style="border-bottom: 1px solid #d8d8d8;" *ngFor="let col of metricColumns; let idx = index;" [class.alt-background]="idx % 2 === 0">
            <div>
            <mat-card class="mat-elevation-z0" style="color: black;">
              <mat-card-content class="card-block target-card">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b style="font-size: 0.9em;">
                          {{row[col.name]?.current.label}}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-card-content>
              <mat-card-footer>
                <table style="width:100%">
                  <tbody>
                    <tr *ngIf="!!row[col.name]?.yoy?.value">
                      <td>
                        {{popLabel ?? 'POP'}}: <b [ngClass]="getTrendTextColor(row[col.name]?.pop?.value, col.name)">{{row[col.name]?.pop?.label ?? row[col.name]?.pop?.value}}</b>
                      </td>
                      <td>
                        YOY: <b [ngClass]="getTrendTextColor(row[col.name]?.yoy?.value, col.name)">{{row[col.name]?.yoy?.label ?? row[col.name]?.yoy?.label}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="!row[col.name]?.yoy?.value">
                      <td style="text-align: center; font-style: italic;">
                        <b [ngClass]="getTrendTextColor(row[col.name]?.pop?.value, col.name)">{{row[col.name]?.pop?.label ?? row[col.name]?.pop?.value}}</b>
                     </td>
                    </tr>
                  </tbody>
                </table>
              </mat-card-footer>
            </mat-card>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-container>

    <!-- Loading View with Pulse Animations -->
    <ng-template #loadingView>
      <thead>
        <th *ngFor="let col of placeholderColumns; let idx = index;" [class.alt-background]="idx % 2 != 0" style="min-width: 200px;" class="sticky-header">
          <div class="title-placeholder" syncPulse style="height: 20px; width: 100px; background-color: #e0e0e0; border-radius: 4px;"></div>
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let row of placeholderRows">
          <td style="text-align:center; border-bottom: 1px solid #d8d8d8;">
            <div class="image-placeholder" syncPulse style="width: 40px; height: 40px; background-color: #e0e0e0; border-radius: 50%; margin: auto;"></div>
          </td>
          <td style="border-bottom: 1px solid #d8d8d8;" *ngFor="let col of placeholderColumns; let idx = index;" [class.alt-background]="idx % 2 === 0">
            <div>
              <mat-card class="mat-elevation-z0" style="color: black;">
                <mat-card-content class="card-block target-card">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div class="value-placeholder" syncPulse style="height: 16px; width: 80%; background-color: #e0e0e0; border-radius: 4px;"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </mat-card-content>
                <mat-card-footer>
                  <table style="width:100%">
                    <tbody>
                      <tr>
                        <td>
                          <div class="value-placeholder" syncPulse style="height: 16px; width: 50%; background-color: #e0e0e0; border-radius: 4px;"></div>
                        </td>
                        <td>
                          <div class="value-placeholder" syncPulse style="height: 16px; width: 50%; background-color: #e0e0e0; border-radius: 4px;"></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </mat-card-footer>
              </mat-card>
            </div>
          </td>
        </tr>
      </tbody>
    </ng-template>

  </table>
</div>
