import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DynamicComponentDirective } from '../../../directives/dynamic-component.directive';
import * as Models from '../../../models/models-index';
import { PanelElementResolverService } from '../panel-element-resolver.service';

@Component({
  selector: 'row-panel',
  templateUrl: './row-panel.component.html',
  styleUrls: ['./row-panel.component.scss']
})
export class RowPanelComponent implements Models.IRowPanel, OnInit, AfterViewInit {

  @ViewChild('innerRowPanelContainer') innerRowPanelContainer: ElementRef;
  @ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent!: DynamicComponentDirective;

  private _panels: Models.Panel[] = [];
  @Input()
  set panels(value: Models.Panel[]) {
    this._panels = value;
    this.checkAndGeneratePanels();
  }
  get panels(): Models.Panel[] {
    return this._panels;
  }

  private _dataSets: Models.DataSet[] = [];
  @Input()
  set dataSets(value: Models.DataSet[]) {
    this._dataSets = value;
    this.updateChildComponentsDataSets();
  }
  get dataSets(): Models.DataSet[] {
    return this._dataSets;
  }

  private _rowIndex: number;
  @Input()
  set rowIndex(value: number) {
    this._rowIndex = value;
    this.checkAndGeneratePanels();
  }
  get rowIndex(): number {
    return this._rowIndex;
  }

  @Input() rowPanelId: string;
  @Input() translationService: Models.ITranslationService;

  panelTitle: string;
  elementId: string;

  private componentRefs: Models.PanelComponent[] = [];

  constructor(
    private resolverService: PanelElementResolverService,
    private renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    // Generate panels immediately if panels and rowIndex are available
    this.checkAndGeneratePanels();
  }

  ngAfterViewInit(): void {
    if (this.elementId && this.innerRowPanelContainer?.nativeElement) {
      this.renderer2.setAttribute(this.innerRowPanelContainer.nativeElement, 'id', this.elementId);
    }
  }

  private checkAndGeneratePanels() {
    if (this.panels && this.rowIndex !== undefined) {
      this.elementId = `panel-row-${this.rowIndex}`;

      if (this.innerRowPanelContainer?.nativeElement) {
        this.renderer2.setAttribute(this.innerRowPanelContainer.nativeElement, 'id', this.elementId);
      }

      this.generatePanels();
    }
  }

  private generatePanels() {
    const viewContainerRef = this.dynamicComponent.viewContainerRef;
    viewContainerRef.clear();
    this.componentRefs = [];

    this.panels.forEach((panel, index) => {
      const componentFactory = this.resolverService.resolvePanelComponent(panel.type);
      const componentRef = viewContainerRef.createComponent<Models.PanelComponent>(componentFactory);
      componentRef.instance.panelConfig = panel;
      componentRef.instance.rowPanelId = this.elementId;
      componentRef.instance.translationService = this.translationService;
      
      // Set dataSets to the current value (may be empty or undefined)
      if(this.dataSets?.length) {
        componentRef.instance.dataSets = this.dataSets;
      }

      this.componentRefs.push(componentRef.instance);

      if (index !== this.panels.length - 1) {
        this.renderer2.setStyle(componentRef.location.nativeElement, 'margin-right', '15px');
      }

      this.renderer2.setStyle(componentRef.location.nativeElement, 'width', '100%');
      this.renderer2.setStyle(componentRef.location.nativeElement, 'min-width', '0px');
    });
  }

  private updateChildComponentsDataSets() {
    // Update dataSets on child components when dataSets become available
    if (this.componentRefs && this.componentRefs.length > 0) {
      this.componentRefs.forEach(componentInstance => {
        componentInstance.dataSets = this.dataSets;
      });
    }
  }
}
