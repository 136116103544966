import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import * as Models from '../../../models/models-index';
import { SeriesOptionsType } from 'highcharts';
import { ChartService } from '../../chart/chart.service';
import { map, startWith, takeWhile } from 'rxjs/operators';
import { kpiLineComparisonChartPlaceholder } from '../../../constants/highcharts-placeholders';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kpi-line-comparison-chart',
  templateUrl: './kpi-line-comparison-chart.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-line-comparison-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiLineComparisonChartComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetrics: string[];

  currentChart: Chart;
  chartTitle: string = '';
  locale: any;
  colors = [];
  componentActive: boolean = true;

  placeholderChart: Chart;

  constructor(
    private chartService: ChartService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.initializePlaceholder();
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  private generateChart(seriesData: any[], xAxisLables: any[], yAxisOptions?: any[]) {
    return new Chart({
      chart: {
        type: 'spline'
      },
      credits: { enabled: false },
      colors: this.colors,
      title: { text: this.chartTitle },
      xAxis: {
        allowDecimals: false,
        categories: xAxisLables
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let s = '';

          for (let i = 0; i < this.points.length; i++) {
            const myPoint = this.points[i];
            s += '<br/>' + myPoint.series.name + ': ';

            // If 'name' property is defined, show it. Otherwise, show 'y' property.
            s += myPoint.point.name ? myPoint.point.name : myPoint.y;
          }
          return s;
        },
      },
      plotOptions: {
        area: {
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      yAxis: yAxisOptions,
      series: seriesData
    });
  }

  private processData(dataSet: Models.DataSet): void {
    this.chartService.reflowChart$
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(() => {
        setTimeout(() => {
          this.currentChart.ref.reflow();
        }, 200);
      });

    this.colors = this.settings.chartColors ?? [this.settings.chartPrimaryColor, this.settings.chartSecondaryColor];

    // we assume the first dimension is the date dimension and the second is the series name
    const [dateDim, seriesNameDim] = this.settings.dimensionNames;
    const metricIndexMapping = dataSet.columns.reduce((map, col, index) => {
      map[col.name] = { ...col, index };
      return map;
    }, {} as Record<string, typeof dataSet.columns[0] & { index: number }>);

    // Generate x labels based on the dates we want to trend out
    const xAxisLabels = [...new Set(dataSet.rows.map(m => m[metricIndexMapping[dateDim].index].label))];

    // get the distinct series names from the data set based on the series name dimension
    const seriesNames = dataSet.rows.map(m => m[metricIndexMapping[seriesNameDim].index].value as string).filter((v, i, a) => a.indexOf(v) === i);

    const seriesData = [];
    const metric = metricIndexMapping[this.selectedMetrics[0]];
    const seriesNameIndex = metricIndexMapping[seriesNameDim].index;

    for (const seriesName of seriesNames) {
      const data = dataSet.rows.filter(r => r[seriesNameIndex].value == seriesName).map(m => {
        const { value, label } = m[metric.index];

        return {
          y: value,
          name: label ? label : value
        };
      });

      seriesData.push({
        name: seriesName,
        data: data
      });
    }

    // generate title
    this.chartTitle = metric.displayName;

    // y-axis options
    const yAxisOptions = [<Highcharts.YAxisOptions>{ title: metric.displayName }];

    // Generate the actual chart
    this.currentChart = this.generateChart(seriesData, xAxisLabels, yAxisOptions);
  }

  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }
    
    this.placeholderChart = new Chart(kpiLineComparisonChartPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);

    this.changeDetectorRef.markForCheck();
  }
}
