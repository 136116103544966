<!-- Loaded View -->
<ng-container *ngIf="isDataSetLoaded$ | async; else loadingView">
  <div class="row" *ngIf="funnelRows && funnelRows.length">
    <div class="processrowheader">
      <div class="box-label"></div>
      <div class="box" style="position: sticky" *ngFor="let header of headers">{{ header }}</div>
    </div>
  </div>
  <div class="funnel-row" *ngFor="let row of funnelRows">
    <div class="row">
      <div class="processgoalrow">
        <div class="box-label"></div>
        <div class="box" *ngFor="let segment of row.segments">{{ segment.descriptionValue }}</div>
      </div>
    </div>
    <div class="row">
      <div class="processrow">
        <div class="box-label">{{row.title || row.name }}</div>
        <ng-container *ngFor="let segment of getSegmentsForDisplay(row)">
          <div [ngClass]="segment.classes">{{segment.value}}</div>
          <svg *ngIf="segment.path" class="box1" style="width: 25px">
            <path [attr.d]="segment.path" [attr.class]="segment.pathClass" />
          </svg>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<!-- Loading View with Pulse Animations -->
<ng-template #loadingView>
  <div class="row">
    <div class="processrowheader">
      <div class="box-label"></div>
      <div class="box" style="position: sticky" *ngFor="let i of headerPlaceholders">
        <div class="header-placeholder" syncPulse style="height: 20px; width: 80%; background-color: #e0e0e0; border-radius: 4px;"></div>
      </div>
    </div>
  </div>

  <div class="funnel-row" *ngFor="let i of rowPlaceholders">
    <div class="row">
      <div class="processgoalrow">
        <div class="box-label"></div>
        <div class="box" *ngFor="let j of segmentPlaceholders">
          <div class="description-placeholder" syncPulse style="height: 20px; width: 100%; background-color: #e0e0e0; border-radius: 4px;"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="processrow">
        <div class="box-label">
          <div class="label-placeholder" syncPulse style="height: 20px; width: 80%; background-color: #e0e0e0; border-radius: 4px;"></div>
        </div>
        <ng-container *ngFor="let j of segmentPlaceholders">
          <div class="value-placeholder" syncPulse style="height: 20px; width: 100%; background-color: #e0e0e0; border-radius: 4px;"></div>
          <svg class="box1" style="width: 25px">
            <rect width="25" height="20" style="fill: #e0e0e0;"></rect>
          </svg>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>