<mat-toolbar class="filter-bar mat-elevation-z3" id="app-filterbar">
  <mat-icon color="primary">filter_list</mat-icon>
  <mat-chip-list>

    <!-- Placeholders: Show when filters are loading -->
    <ng-container *ngIf="isLoadingFilters$ | async; else showFilters">
      <mat-chip
        class="mat-elevation-z2 placeholder-chip" syncPulse aria-hidden="true">
        <div class="placeholder-content">
          <div class="placeholder-icon"></div>
          <div class="placeholder-text"></div>
        </div>
      </mat-chip>

      <mat-chip
        class="mat-elevation-z2 placeholder-chip" syncPulse aria-hidden="true">
        <div class="placeholder-content">
          <div class="placeholder-icon"></div>
          <div class="placeholder-text"></div>
        </div>
      </mat-chip>
    </ng-container>

    <!-- Actual Filters: Show when filters are loaded -->
    <ng-template #showFilters>
      <span #filterChip *ngFor="let filter of reportViewFilterSelections$ | async">
        <mat-chip class="mat-elevation-z2" [class.removable]="filter.removable" [removable]="filter.removeable"
          color="primary" selected="true" (removed)="removeFilter(filter)">
          <a mat-button id="filter-text" (click)="onFilterClicked($event, filterChip, filter)">
            {{getDisplayValue(filter, locale) | translate: locale | async}}
          </a>
          <button mat-icon-button [class.lock-button]="filter.lockable" (click)="lockFilter(filter)">
            <mat-icon class="lock-button-icon" [class.locked]="filter.locked">
              {{filter.locked ? 'lock' : 'lock_open'}}
            </mat-icon>
          </button>
          <mat-icon matChipRemove *ngIf="filter.removeable">cancel</mat-icon>
        </mat-chip>
      </span>

      <button #tempBtn (click)="showFilterPanel(tempBtn._elementRef)" mat-button color="primary" class="filter-button">
        + {{'Filter' | translate: locale | async}}
      </button>
    </ng-template>

  </mat-chip-list>
  <span style="flex: 1 1 auto"></span>
  <button mat-icon-button color="primary" class="clear-filter-button" (click)="resetFilters()">
    <mat-icon>replay</mat-icon>
  </button>
</mat-toolbar>
