import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import { kpiSparkAreaFillPlaceholder } from '../../../constants/highcharts-placeholders';
import { BehaviorSubject, map, startWith } from 'rxjs';
import { DataSet, ElementComponent } from '../../../models/report-view.models';

@Component({
  selector: 'kpi-spark-area-fill',
  templateUrl: './kpi-spark-area-fill.component.html',
  styleUrls: ['../panel-elements.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class KpiSparkAreaFillComponent implements ElementComponent, OnInit {
  @Input() set dataSet(value: any) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));

  @Input() settings: any;

  currentChart: any;
  elementLabel: any;
  placeholderChart: Chart;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.initializePlaceholder();
  }

  private generateChart(seriesData: any[], lineColor: string) {
    const categories = seriesData.map(sd => sd.name);
    const chartSeries: SeriesOptionsType[] = [{
      type: 'area',
      data: seriesData,
      color: lineColor,
      lineWidth: 2,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 3,
        color: lineColor
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }];

    var chart = new Chart({
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      chart: {
        showAxes: false,
        height: 120
      },
      tooltip: {
        outside: true,
        pointFormat: '{point.displayLabel}: <b>{point.displayValue}</b>',
        style: {
          zIndex: 100
        }
      },
      // AXES.....
      xAxis: {
        title: { text: null },
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineColor: 'transparent',
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              x2: 1,
              y1: 0,
              y2: 0
            },
            stops: [
              [0, this.hexToRGB(lineColor, 0.2)],
              [1, this.hexToRGB(lineColor, 0.55)]
            ]
          }
        }
      },
      series: chartSeries
    });

    return chart;
  }

  hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  private processData(dataSet: any): void {
    let metricIndex = dataSet.columns.findIndex(col => col.name === this.settings.metricName);
    let dimensionIndex = dataSet.columns.findIndex(col => col.name === this.settings.dimensionName);
    let seriesData = dataSet.rows.map(row => row[metricIndex].value);
    let categories = dataSet.rows.map(row => row[dimensionIndex].label);
    let chartColor = this.settings.color || '#000000';
    let metricDisplayName = dataSet.columns[metricIndex].displayName ?? dataSet.columns[metricIndex].name;
    this.elementLabel = metricDisplayName;

    let seriesDataV2 = [];

    dataSet.rows.forEach(row => {
      let metricItem = row[metricIndex];
      let dimensionItem = row[dimensionIndex];
      seriesDataV2.push({
        name: metricDisplayName,
        displayValue: metricItem.label ?? metricItem.value,
        displayLabel: dimensionItem.label ?? metricItem.name,
        y: metricItem.value
      })
    })

    this.currentChart = this.generateChart(seriesDataV2, chartColor);
  }
  
  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }
    
    this.placeholderChart = new Chart(kpiSparkAreaFillPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);

    this.changeDetectorRef.markForCheck();
  }
}
