import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[syncPulse]' // Directive to apply pulse animation
})
export class SyncPulseDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  // Runs after the view has been initialized
  ngAfterViewInit(): void {
    // Get all elements with the same directive
    const animatedElements = document.querySelectorAll('[syncPulse]');
    
    // Reapply the animation class to all elements to synchronize them
    animatedElements.forEach((element) => {
      this.renderer.removeClass(element, 'animate-pulse');
      setTimeout(() => {
        this.renderer.addClass(element, 'animate-pulse');
      }, 0);
    });
  }
}
