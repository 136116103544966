import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as ElementModels from '../../../models/report-view.models';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import * as Models from '../../../models/models-index';
import { ChartService } from '../../chart/chart.service';
import { map, startWith, takeWhile } from 'rxjs/operators';
import * as SharedServices from '../../../services/services-index';
import { BehaviorSubject, Subscription } from 'rxjs';
import { getChartColors } from '../panel-utils';
import { kpiMultiLineChartPlaceholder } from '../../../constants/highcharts-placeholders';

@Component({
  selector: 'kpi-multi-line-chart',
  templateUrl: './kpi-multi-line-chart.component.html',
  styleUrls: ['../panel-elements.scss', './kpi-multi-line-chart.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiMultiLineChartComponent implements Models.ElementComponent, OnInit, OnDestroy {
  @Input() set dataSet(value: Models.DataSet) {
    if (!!value) {
      this.processData(value);
      this.dataSetSubject.next(value);
    } else {
      this.dataSetSubject.next(undefined);
    }
  }

  dataSetSubject: BehaviorSubject<Models.DataSet> = new BehaviorSubject(undefined);
  isDataSetLoaded$ = this.dataSetSubject.pipe(
    map((dataSet) => !!dataSet),
    startWith(false));
    
  @Input() settings: ElementModels.ElementSettings;
  @Input() selectedMetrics: string[];

  subscriptions: Subscription[] = [];
  currentChart: Chart;
  chartTitle: string = '';
  locale: string;
  componentActive: boolean = true;
  translationService: Models.ITranslationService;

  placeholderChart: Chart;

  constructor(
    private chartService: ChartService,
    private localeService: SharedServices.LocaleService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initializePlaceholder();

    // Subscribe to locale changes
    this.subscriptions.push(
      this.localeService.locale$.subscribe(loc => this.locale = loc),
      this.chartService.reflowChart$
        .pipe(takeWhile(() => this.componentActive))
        .subscribe(() => {
          setTimeout(() => {
            if (this.currentChart && this.currentChart.ref) {
              this.currentChart.ref.reflow();
            }
          }, 200);
        })
    );
  }

  ngOnDestroy(): void {
    this.componentActive = false;
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private generateChart(seriesData: any[], xAxisLabels: any[], yAxisOptions: any[]) {
    return new Chart({
      chart: {
        type: 'spline'
      },
      credits: { enabled: false },
      colors: getChartColors(this.settings),
      title: { text: this.chartTitle },
      xAxis: {
        allowDecimals: false,
        categories: xAxisLabels
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let s = '';
          this.points.forEach(point => {
            s += `<br/>${point.series.name}: ${point.point.name ?? point.y}`;
          });
          return s;
        },
      },
      plotOptions: {
        area: {
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      yAxis: yAxisOptions,
      series: seriesData
    });
  }

  private isPercentageMetric(metricName: string, dataSet: Models.DataSet): boolean {
    if (!dataSet || !metricName) {
      return false;
    }
    const metricIndex = dataSet.columns.findIndex(col => col.name === metricName);
    if (metricIndex === -1) {
      return false;
    }
    const metric = dataSet.columns[metricIndex];

    if (dataSet.rows.length === 0) return false;

    const firstRowValue = dataSet.rows[0][metricIndex];
    if (!firstRowValue) {
      return false;
    }

    return firstRowValue.label?.includes('%') || metric.name?.includes('%');
  }

  private processData(dataSet: Models.DataSet): void {
    const metricIndexMapping = dataSet.columns.reduce((map, col, index) => {
      map[col.name] = { ...col, index };
      return map;
    }, {} as Record<string, typeof dataSet.columns[0] & { index: number }>);

    // Generate x labels
    const dimName = this.settings.dimensionName;
    if (!dimName || !metricIndexMapping[dimName]) {
      // Handle missing dimension name or invalid dimension
      this.dataSetSubject.next(undefined);

      return;
    }
    const xAxisLabels = dataSet.rows.map(m => m[metricIndexMapping[dimName].index]?.label);

    let seriesData = [];
    this.chartTitle = '';

    // Generate series data
    this.selectedMetrics.forEach(metric => {
      if (!metricIndexMapping[metric]) {
        // Handle invalid metric
        return;
      }
      const data = dataSet.rows.map(m => {
        const value = m[metricIndexMapping[metric].index]?.value ?? 0;
        const label = m[metricIndexMapping[metric].index]?.label;

        return {
          y: value,
          name: label ? label : value
        };
      });

      seriesData.push({
        name: metricIndexMapping[metric].displayName,
        data: data
      });

      // Generate title
      if (this.chartTitle !== '') this.chartTitle += ' vs. ';
      this.chartTitle += metricIndexMapping[metric].displayName;
    });

    // Generate y-axis options and link them to series
    let yAxisOptions = this.selectedMetrics.map((metric, index) => {
      const yAxisIsPercentage = this.isPercentageMetric(metric, dataSet);
      return {
        title: { text: metricIndexMapping[metric].displayName },
        opposite: index % 2 === 1, // Put every second axis on the right side
        labels: {
          formatter: function () {
            return yAxisIsPercentage ? (this.value * 100).toFixed(0) + '%' : this.value.toString();
          }
        }
      };
    });

    // Link series to the corresponding y-axis
    seriesData.forEach((series, index) => {
      series.yAxis = index; // Link this series to its y-axis
    });

    // Generate the actual chart
    this.currentChart = this.generateChart(seriesData, xAxisLabels, yAxisOptions);
  }

  private initializePlaceholder(): void {
    if (this.placeholderChart) {
      // exit if chart is already initialized
      return;
    }

    this.placeholderChart = new Chart(kpiMultiLineChartPlaceholder);
    setTimeout(() => {
      if (this.placeholderChart && this.placeholderChart.ref) {
        this.placeholderChart.ref.reflow();
      }
    }, 200);
    // this.changeDetector.markForCheck();
  }
}
